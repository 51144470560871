class ClassBuilder {

    constructor() {

        this.classList = [];
    }

    static add(className) {

        let newBuilder = new ClassBuilder();

        return newBuilder.add(className);
    }

    add(className) {

        let classIndex = this.classList.indexOf(className);

        if(classIndex === -1)
            this.classList.push(className);
        
        return this;
    }

    remove(className) {

        let classIndex = this.classList.indexOf(className);

        if(classIndex !== -1)
            this.classList.splice(classIndex, 1);

        return this;
    }

    toggle(className) {

        let classIndex = this.classList.indexOf(className);

        if(classIndex !== -1)
            this.classList.splice(classIndex, 1);
        else
            this.classList.push(className);

        return this;
    }

    clear() {

        this.classList = [];
        return this;
    }

    serialize() {

        let outputClassAttribute = '';

        for(let className of this.classList)
            outputClassAttribute += className + ' ';

        return outputClassAttribute.trim();
    }
}

export default ClassBuilder;