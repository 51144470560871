import ClassBuilder from '../../../../helpers/class_builder';
import React from 'react';

import '../output_item/OutputItem.css';
import Helpers from '../../../../helpers/helpers';

class Web extends React.Component {
    
    constructor(props) {

        super(props);

        this.state = {

            class: ClassBuilder.add('chat-item')
                               .add('rosie-chat-item')
        }
    }

    componentDidMount() {
        
        this.props.onItemAdded();
        setTimeout(() => {

            this.setState({

                class: this.state.class.add('animate')
            })

            setTimeout(() => {
    
                this.props.onResponse(this.props.elementId, 1);
            }, 700);

        }, 100);
    }

    removeUrlPrefix(url) {

        if(url.indexOf('Go to ') === 0)
            url = url.substr('Go to '.length);
        
        if(url.indexOf('http') !== 0)
            url = 'http://' + url;

        return url;
    }

    render() {

        return (

            <a href={this.removeUrlPrefix(this.props.text)} target="_blank" rel="noopener noreferrer" className={this.state.class.serialize()}>{Helpers.truncateUrl(this.props.text, 50)}</a>
        );
    }
}

export default Web;