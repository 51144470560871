import { Helpers } from "../../helpers";

class ConditionalModel {

    constructor(id, statements, outputs) {

        if(!Helpers.isInteger(id))
            throw new Error(`'id' argument must be an Integer`);
            
        this._id = id;

        this.setStatements(statements);
        this.setOutputs(outputs);
    }

    export() {

        return {

            id: this._id,
            type: 'comparator',
            followups: this._exportFollowups()
        };
    }

    _exportFollowups() {

        let followups = [];

        for(let i=0; i<this._statements.length; i++) {

            followups.push({

                statement: this._statements[i],
                followup: this._outputs[i]
            });
        }

        return followups;
    }

    getType() {
        return 'conditional';
    }

    isId(id) {

        return this._id === id;
    }

    getId() {

        return this._id;
    }

    getOutputs() {

        return this._outputs;
    }
    
    getOutput(index) {

        return this._outputs[index];
    }    

    getOutputCount() {

        return this._outputs.length;
    }

    setOutputs(outputs) {

        if(Helpers.isDefined(outputs))
            if(!Helpers.isArray(outputs) || outputs.length !== this._statements.length)
                throw new Error(`'outputs' argument must be an Array of length 'statements'`);

        if(Helpers.isDefined(outputs)) {

            this._outputs = outputs;
        }
        else 
        if(Helpers.isUndefined(this._outputs) || this._outputs.length !== this._brakepoints.length) {

            this._outputs = [];

            for(let i=0; i<this._brakepoints.length + 1; i++)
                this._outputs.push(null);
        }
    }

    setOutput(index, value) {

        if(!Helpers.isIndexInBounds(index, this._outputs.length))
            throw new Error(`Index out of bounds`);

            if(!(Helpers.isInteger(value) || value === null))
            throw new Error(`'value' argument must be an Integer or null`);

        this._outputs[index] = value;
    }

    getStatements() {

        return this._statements;
    }
    
    getStatement(index) {

        return this._statements[index];
    }    

    getStatementCount() {

        return this._statements.length;
    }

    setStatements(statements) {

        if(!Helpers.isArray(statements))
            throw new Error(`'statements' argument must be an array of Strings`);

        if(statements.length < 1)
            throw new Error(`Conditional element must have at least 1 state`);

        for(let response of statements)
            if(!Helpers.isString(response))
                throw new Error(`'statements' argument elements must be Strings`);

        this._statements = statements;

        if(Helpers.isUndefined(this._outputs) || this._outputs.length !== statements.length) {

            this._outputs = [];

            for(let i=0; i<this._statements.length; i++)
                this._outputs.push(null);
        }
    }

    setStatement(index, value) {

        if(!Helpers.isIndexInBounds(index, this._statements.length))
            throw new Error(`Index out of bounds`);
    
        if(!Helpers.isString(value))
            throw new Error(`'value' argument must be a String`);

        this._statements[index] = value;
    }

    addStatement(statement, position) {

        if(Helpers.isUndefined(position)) {

            this._statements.push(statement);
            this._outputs.push(null);
        }
        else {

            if(!Helpers.isIndexInBounds(position, this._statements.length))
                throw new Error(`Index out of bounds`);

            this._statements.splice(position, 0, statement);
            this._outputs.splice(position, 0, null);
        }
    }

    deleteStatement(index) {
        
        if(this._statements.length <= 1)
            throw new Error(`Conditional element must have at least 1 statement`);

        if(!Helpers.isIndexInBounds(index, this._statements.length))
            throw new Error(`Index out of bounds`);

        this._statements.splice(index, 1);
        this._outputs.splice(index, 1);
    }

    getVariable() {
        return null;
    }
}

export default ConditionalModel;