import OutputItem from '../output_item/OutputItem';
import TypingItem from '../output_item/TypingItem';
import React, { Fragment } from 'react';

class Output extends React.Component {
    
    constructor(props) {

        super(props);

        this.state = {

            outputLines: [],
            isTypingMessageVisible: false
        }

        this.preLoadDelay = 150;
        this.postLoadMinDelay = 700;
        this.postLoadMaxDelay = 5000;
    }

    getTextPostLoadDelay(text) {
        
        let textBasedDelay = 1000 * text.length / 30; //Assuming average 25 letter/s
        
        return textBasedDelay < this.postLoadMinDelay ? this.postLoadMinDelay :
               textBasedDelay > this.postLoadMaxDelay ? this.postLoadMaxDelay :
               textBasedDelay;
    }

    shouldDisplayTypingDots(text) {

        return this.getTextPostLoadDelay(text) > 1500;
    }

    addOutputLine(lineIndex) {

        setTimeout(() => {

            const textLines = this.props.textLines;
            const from = this.props.from;

            let newOutput = <OutputItem key={lineIndex} text={textLines[lineIndex]} from={from} onItemAdded={ this.props.onItemAdded }/>

            this.setState({

                outputLines: [...this.state.outputLines, newOutput]
            });

            setTimeout(() => {

                if(lineIndex < textLines.length - 1) {

                    this.addOutputLine(lineIndex + 1);
                }
                else {

                    this.props.onResponse(this.props.elementId, 1);
                }
            },
            this.getTextPostLoadDelay(textLines[lineIndex]))

            if(this.shouldDisplayTypingDots(textLines[lineIndex])) {

                setTimeout(() => {
                    this.setState({isTypingMessageVisible: true})
                }, 400);
    
                setTimeout(() => {
                    this.setState({isTypingMessageVisible: false})
                }, this.getTextPostLoadDelay(textLines[lineIndex]) - 200);
            }
        },
        this.preLoadDelay);
    }

    componentDidMount() {

        this.props.onItemAdded();
        this.addOutputLine(0);
    }

    render() {

        return (
            <Fragment>
                { this.state.outputLines }
                {
                    this.state.isTypingMessageVisible ?
                    <TypingItem key={'typing'} text={'...'} from={'rosie'} onItemAdded={ this.props.onItemAdded }/>
                :
                    null
                }
            </Fragment>
        );
    }
}

export default Output;