export default class Validators {

    static isBirthYear(value) {
        try {
            let intValue = parseInt(value);
            return 1900 <= intValue && intValue <= 2020;
        }
        catch(err) {
            return false;
        }
    }

    static isPhoneNumber(value) {    
        return /^((\+|00[- ]?)?383[- ]?)?[0-9]{2,3}[- ]?[0-9]{3}[- ]?[0-9]{3}$/.test(value);
    }

    static isFullName(value) {
        return /^[A-Za-z]{2,}[ ]+([A-Za-z]+[.]?[ ]+)?[A-Za-z]{2,}$/.test(value); 
    } 

    static isDefaultInput(value) {
        return value.length >= 1; 
    } 
}