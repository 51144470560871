import Config from '../../config';
import TextContainer from '../../building_blocks/text_container';

import { Point, Size } from '../../helpers';

class AlertGeometry {

    constructor(position) {

        this._position = new Point(position.x, position.y);
        this._size = new Size(0, 0);

        this._textLineContainerHeight = 0;
        this._inputPosition = new Point(0, 0);
        
        this._actionIconPosition = new Point(0, 0);
        this._titlePosition = new Point(0, 0);

        this._iconPosition = new Point(0, 0);
        this._outputPositions = [ new Point(0, 0) ];
    }

    translate(dx, dy) {

        this._position.translate(dx, dy);
        this._inputPosition.translate(dx, dy);

        this._actionIconPosition.translate(dx, dy);
        this._titlePosition.translate(dx, dy);

        this._iconPosition.translate(dx, dy);
        this._outputPositions[0].translate(dx, dy);
    }

    updateGeometry(canvas, font, position, title) {

        let currentPosition = new Point(position.x, position.y);

        this._position.copyFrom(currentPosition);
        this._inputPosition.copyFrom(currentPosition);

        currentPosition.y += Config.Style.ChatItem.Spacing.Vertical + Config.Style.ActionContainer.Size / 2;
        this._actionIconPosition.copyFrom(currentPosition);

        this._textLineContainerHeight = TextContainer.getTextContainerHeight(font);

        currentPosition.y += Config.Style.ActionContainer.Size / 2;
        this._titlePosition.copyFrom(currentPosition);

        currentPosition.y += this._textLineContainerHeight + Config.Style.ChatItem.Spacing.Vertical + Config.Style.Anchors.Size / 2;
        this._outputPositions[0].copyFrom(currentPosition);

        this._updateSize(canvas, font, title);
    }

    _updateSize(canvas, font, title) {

        this._size.height = this._outputPositions[0].y - this._inputPosition.y;
        this._size.width = Config.Style.ActionContainer.Size;

        let titleContainerWidth = TextContainer.getTextContainerWidth(canvas, 
                                                                      font, 
                                                                      this._textLineContainerHeight, 
                                                                      title);

        if(this._size.width < titleContainerWidth)
            this._size.width = titleContainerWidth;
    }

    getActionIconPosition() {

        return this._actionIconPosition;
    }

    getActionTitlePosition() {

        return this._titlePosition;
    }

    getPosition() {

        return this._position;
    }

    getSize() {

        return this._size;
    }

    getInputPosition() {

        return this._inputPosition;
    }

    getOutputPositions() {

        return this._outputPositions;
    }

    getOutputPosition(index) {

        return this._outputPositions[index];
    }

    getOutputCount() {

        return this._outputPositions.length;
    }

    getIconPosition() {

        return this._iconPosition;
    }
}

export default AlertGeometry;