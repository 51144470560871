import Config from '../../config';
import SingleChoiceGeometry from './geometry';
import SingleChoiceModel from './model';

import { Helpers } from '../../helpers';
import { TextContainer, Miscellaneous } from '../../building_blocks/blocks';

class SingleChoice {

    constructor(id, position, responseList, weightList, outputs) {
               
        if(!Helpers.isPoint(position))
            throw new Error(`'position' argument must be of type Point`);

        this._model = new SingleChoiceModel(id, responseList, weightList, outputs);
        this._geometry = new SingleChoiceGeometry(position, this.getModel().getResponses());

        this._shouldUpdateGeometry = true;
    }

    draw(canvas) {

        if(this._shouldUpdateGeometry) {

            this.getGeometry().updateGeometry(canvas, 
                                              Config.Style.Text.Font, 
                                              this.getGeometry().getPosition(),
                                              this.getModel().getResponses());

            this._shouldUpdateGeometry = false;                    
        }


        Miscellaneous.drawInputAnchor(canvas, 
                                      Config.Style.Anchors.Color, 
                                      this.getGeometry().getInputPosition());

        
        for(let i=0; i< this.getGeometry().getOutputCount(); i++) {

            Miscellaneous.drawVerticalLink(canvas, 
                                           Config.Style.Links.Color, 
                                           this.getGeometry().getInputPosition(),
                                           this.getGeometry().getResponsePosition(i));

            Miscellaneous.drawLine(canvas, 
                                   Config.Style.Links.Color, 
                                   this.getGeometry().getResponsePosition(i),
                                   this.getGeometry().getOutputPosition(i));

            Miscellaneous.drawOutputAnchor(canvas, 
                                            Config.Style.Anchors.Color, 
                                            this.getGeometry().getOutputPosition(i));
        }

        for(let i=0; i< this.getGeometry().getResponseCount(); i++) {

            TextContainer.drawTextContainer(canvas, 
                                            Config.Style.ChatItem.Colors.Response.Background, 
                                            Config.Style.ChatItem.Colors.Response.Foreground,
                                            TextContainer.ContainerType().Round,
                                            Config.Style.Text.Font, 
                                            this.getGeometry().getResponsePosition(i), 
                                            this.getModel().getResponse(i));
        }
    }

    invalidate() {

        this._shouldUpdateGeometry = true;
    }

    translate(dx, dy) {

        this.getGeometry().translate(dx, dy);
    }

    isAtLocation(x, y) {

        let position = this.getGeometry().getPosition();
        let size = this.getGeometry().getSize();

        return position.x - size.width / 2 < x && x < position.x + size.width / 2 &&
               position.y < y && y < position.y + size.height;
    }

    getInputPositionAtLocation(location, radius) {

        let inputPosition = this.getGeometry().getInputPosition();

        if(inputPosition.distanceTo(location) < radius)
            return inputPosition;
        else
            return null;
    }

    getOutputPositionAtLocation(location, radius) {

        let outputPositions = this.getGeometry().getOutputPositions();

        for(let outputPosition of outputPositions)
            if(outputPosition.distanceTo(location) < radius)
                return outputPosition;

        return null;
    }

    getOutputAtLocation(location, radius) {

        for(let i=0; i<this.getModel().getOutputCount(); i++)
            if(this.getGeometry().getOutputPosition(i).distanceTo(location) < radius)
                return i;

        return null;
    }

    getModel() {

        return this._model;
    }
    
    getGeometry() {

        return this._geometry;
    }

    exportElement() {

        return {

            id: this.getModel().getId(),
            type: 'single-choice',
            position: this.getGeometry().getPosition(),
            responseList: this.getModel().getResponses(),
            weightList: this.getModel().getWeights(),
            outputs: this.getModel().getOutputs()
        };
    }

    static generateEmptyWeightList(size) {

        let weightList = [];

        for(let i=0; i<size; i++)
            weightList.push(0);

        return weightList;
    }

    static create(elementDescriptor) {

        let validWeightsList = Helpers.isArray(elementDescriptor.weightList) && 
                               elementDescriptor.weightList.length === elementDescriptor.responseList.length;
        
        let importedWeightList = validWeightsList ?
                                 elementDescriptor.weightList :
                                 SingleChoice.generateEmptyWeightList(elementDescriptor.responseList.length);

        return new SingleChoice(elementDescriptor.id, 
                                elementDescriptor.position,
                                elementDescriptor.responseList,
                                importedWeightList,
                                elementDescriptor.outputs);
    }
}

export default SingleChoice;