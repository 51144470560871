import Config from '../config';

class Miscellaneous {

    static drawInputAnchor(canvas, color, position) {

        canvas.lineWidth = Config.Style.Anchors.StrokeWidth;
        
        canvas.strokeStyle = color;
        canvas.fillStyle = Config.Style.Anchors.FillColor;

        let size = Config.Style.Anchors.Size;

        canvas.beginPath();

        canvas.moveTo(position.x - size / 3, position.y - size / 2 + size / 4);
        canvas.lineTo(position.x + size / 3, position.y - size / 2 + size / 4);
        canvas.lineTo(position.x, position.y + size / 4);
        canvas.closePath();

        canvas.fill();        
        canvas.stroke();
    }
    
    static drawOutputAnchor(canvas, color, position) {

        canvas.lineWidth = Config.Style.Anchors.StrokeWidth;
        
        canvas.strokeStyle = color;
        canvas.fillStyle = Config.Style.Anchors.FillColor;

        let size = Config.Style.Anchors.Size;

        canvas.beginPath();

        canvas.moveTo(position.x - size / 3, position.y - size / 4);
        canvas.lineTo(position.x + size / 3, position.y - size / 4);
        canvas.lineTo(position.x, position.y + size / 2 - size / 4);
        canvas.closePath();
   
        canvas.fill();        
        canvas.stroke();
    }

    static drawLinkBox(canvas, position) {

        canvas.lineWidth = Config.Style.LinkBox.StrokeWidth;
        canvas.strokeStyle = Config.Style.LinkBox.Color;

        let size = Config.Style.LinkBox.Size;

        canvas.beginPath();

        canvas.strokeRect(position.x - size / 2, position.y - size / 2, size, size);

        canvas.stroke();
    } 
    
    static drawLine(canvas, color, from, to) {

        canvas.lineWidth = Config.Style.Links.StrokeWidth;
        canvas.strokeStyle = color;

        canvas.beginPath();

        canvas.moveTo(from.x, from.y);
        canvas.lineTo(to.x, to.y);
        
        canvas.stroke();
    }

    static drawLink(canvas, color, from, to) {

        canvas.lineWidth = Config.Style.Links.StrokeWidth;
        canvas.strokeStyle = color;

        canvas.beginPath();

        if(Math.abs(from.x - to.x) < Math.abs(from.y - to.y)) {

            let breakPoint = (from.y + to.y) / 2;

            canvas.moveTo(from.x, from.y);
            canvas.lineTo(from.x, breakPoint);
            canvas.lineTo(to.x, breakPoint);
            canvas.lineTo(to.x, to.y);
        }
        else {

            let breakPoint = (from.x + to.x) / 2;

            canvas.moveTo(from.x, from.y);
            canvas.lineTo(breakPoint, from.y);
            canvas.lineTo(breakPoint, to.y);
            canvas.lineTo(to.x, to.y);
        }

        canvas.stroke();
    }

    static drawVerticalLink(canvas, color, from, to) {

        canvas.lineWidth = Config.Style.Links.StrokeWidth;
        canvas.strokeStyle = color;

        canvas.beginPath();

        let breakPoint = (from.y + to.y) / 2;

        canvas.moveTo(from.x, from.y);
        canvas.lineTo(from.x, breakPoint);
        canvas.lineTo(to.x, breakPoint);
        canvas.lineTo(to.x, to.y);    

        canvas.stroke();
    }

    static drawStartSymbol(canvas, position) {

        let size = Config.Style.TerminationSymbol.Radius;

        canvas.fillStyle = Config.Style.TerminationSymbol.Color;

        canvas.beginPath();
        canvas.arc(position.x, position.y, size, 0, 2 * Math.PI);
        canvas.fill();

        canvas.fillStyle = '#fff';

        canvas.beginPath();
        canvas.arc(position.x, position.y, 0.8 * size, 0, 2 * Math.PI);
        canvas.fill();
    }

    static drawTerminationSymbol(canvas, success, position) {

        canvas.fillStyle = Config.Style.TerminationSymbol.Color;
        canvas.strokeStyle = '#fff';

        let size = Config.Style.TerminationSymbol.Radius;

        canvas.beginPath();
        canvas.arc(position.x, position.y, size, 0, 2 * Math.PI);
        canvas.fill();

        canvas.beginPath();
        canvas.arc(position.x, position.y, 0.8 * size, 0, 2 * Math.PI);
        canvas.stroke();

        if(!success) {

            canvas.beginPath();
            canvas.moveTo(position.x - size / 3, position.y - size / 3);
            canvas.lineTo(position.x + size / 3, position.y + size / 3);
            canvas.moveTo(position.x + size / 3, position.y - size / 3);
            canvas.lineTo(position.x - size / 3, position.y + size / 3);
            canvas.stroke();        
        }
    }

    static drawAlertSymbol(canvas, position, size) {

        canvas.fillStyle = Config.Style.Alert.Colors.Background;

        canvas.beginPath();
        canvas.arc(position.x, position.y, size, 0, 2 * Math.PI);
        canvas.fill();

        canvas.strokeStyle = Config.Style.Alert.Colors.Foreground;
        canvas.lineWidth = 1.5;

        canvas.beginPath();
        canvas.moveTo(position.x, position.y - size / 1.6);
        canvas.lineTo(position.x - size / 2.5, position.y + size / 2);
        canvas.lineTo(position.x, position.y + size / 4);
        canvas.lineTo(position.x + size / 2.5, position.y + size / 2);
        canvas.closePath();
        canvas.stroke();
    }

    static drawElementSelectionBox(canvas, position, size) {

        canvas.lineWidth = Config.Style.SelectionBox.StrokeWidth;
        canvas.strokeStyle = Config.Style.SelectionBox.Color;

        let padding = Config.Style.SelectionBox.Padding;

        canvas.beginPath();
        canvas.strokeRect(position.x - size.width / 2 - padding, position.y - padding, size.width + 2 * padding, size.height + 2 * padding);
        canvas.stroke();
    }

    static drawSelectionBox(canvas, position, size) {

        canvas.lineWidth = Config.Style.GlobalSelectionBox.StrokeWidth;
        canvas.strokeStyle = Config.Style.GlobalSelectionBox.Color;

        let padding = Config.Style.GlobalSelectionBox.Padding;

        canvas.beginPath();
        canvas.strokeRect(position.x - padding, position.y - padding, size.width + 2 * padding, size.height + 2 * padding);
        canvas.stroke();
    }
}

export default Miscellaneous;