import Config from '../../config';
import TextContainer from '../../building_blocks/text_container';
import ResponseArray from '../../building_blocks/response_array';

import { Point, Size } from '../../helpers';

class MeasurementGeometry {

    constructor(position, responseList) {

        this._position = new Point(position.x, position.y);
        this._size = new Size(0, 0);

        this._textLineContainerHeight = 0;
        this._inputPosition = new Point(0, 0);
        
        this._responsePositions = [];
        this._outputPositions = [];

        this._actionIconPosition = new Point(0, 0);
        this._titlePosition = new Point(0, 0);
        this._branchPosition = new Point(0, 0);

        for(let i=0; i<responseList.length; i++) {

            this._responsePositions.push(new Point(0, 0));
            this._outputPositions.push(new Point(0, 0));
        }
    }

    translate(dx, dy) {

        this._position.translate(dx, dy);
        this._inputPosition.translate(dx, dy);

        this._actionIconPosition.translate(dx, dy);
        this._titlePosition.translate(dx, dy);
        this._branchPosition.translate(dx, dy);

        for(let i=0; i<this._outputPositions.length; i++) {

            this._responsePositions[i].translate(dx, dy);
            this._outputPositions[i].translate(dx, dy);
        }
    }

    updateGeometry(canvas, font, position, responseList, title) {

        let currentPosition = new Point(position.x, position.y);

        if(responseList.length !== this._responsePositions.length) {
            
            this._responsePositions = [];
            this._outputPositions = [];

            for(let i=0; i<this._responseList.length; i++) {

                this._responsePositions.push(new Point(0, 0));
                this._outputPositions.push(new Point(0, 0));
            }

            this._size.width = 0;
        }

        this._position.copyFrom(currentPosition);
        this._inputPosition.copyFrom(currentPosition);

        currentPosition.y += Config.Style.ChatItem.Spacing.Vertical + Config.Style.ActionContainer.Size / 2;
        this._actionIconPosition.copyFrom(currentPosition);

        this._textLineContainerHeight = TextContainer.getTextContainerHeight(font);

        currentPosition.y += 0.45 * Config.Style.ActionContainer.Size;
        this._titlePosition.copyFrom(currentPosition);

        let responsePositions = ResponseArray.getResponseArrayPositions(canvas, font, currentPosition, responseList);

        currentPosition.y += 1.1 * this._textLineContainerHeight;
        this._branchPosition.copyFrom(currentPosition);

        currentPosition.y += Config.Style.ChatItem.Spacing.BetweenQuestionResponse;

        for(let i=0; i<this._responsePositions.length; i++) {

            this._responsePositions[i].y = currentPosition.y
            this._responsePositions[i].x = responsePositions[i];
        }

        currentPosition.y += this._textLineContainerHeight + Config.Style.ChatItem.Spacing.Vertical + Config.Style.Anchors.Size / 2;
            
        for(let i=0; i<this._outputPositions.length; i++) {

            this._outputPositions[i].y = currentPosition.y;
            this._outputPositions[i].x = responsePositions[i]
        }

        this._updateSize(canvas, font, responseList, title);
    }

    _updateSize(canvas, font, responseList, title) {

        this._size.height = this._outputPositions[0].y - this._inputPosition.y;
        this._size.width = ResponseArray.getResponseArrayWidth(canvas, font, responseList);

        let titleContainerWidth = TextContainer.getTextContainerWidth(canvas, 
                                                                      font, 
                                                                      this._textLineContainerHeight, 
                                                                      title);

        if(this._size.width < titleContainerWidth)
            this._size.width = titleContainerWidth;
    }

    getActionIconPosition() {

        return this._actionIconPosition;
    }

    getActionTitlePosition() {

        return this._titlePosition;
    }
 
    getBranchPosition() {

        return this._branchPosition;
    }

    getPosition() {

        return this._position;
    }

    getSize() {

        return this._size;
    }

    getInputPosition() {

        return this._inputPosition;
    }

    getOutputPositions() {

        return this._outputPositions;
    }

    getOutputPosition(index) {

        return this._outputPositions[index];
    }

    getOutputCount() {

        return this._outputPositions.length;
    }

    getResponsePositions() {

        return this._responsePositions;
    }

    getResponsePosition(index) {

        return this._responsePositions[index];
    }
    
    getResponseCount() {

        return this._responsePositions.length;
    }

    getTextLinePositions() {

        return this._textLinePositions;
    }

    getTextLinePosition(index) {

        return this._textLinePositions[index];
    }
    
    getTextLineCount() {

        return this._textLinePositions.length;
    }
}

export default MeasurementGeometry;