class Helpers {

    static isDefined(value) {

        return typeof value !== 'undefined';
    }

    static getElapsedTimeString(date) {

        let differenceMinutes = Math.floor((Date.now() - date.getTime()) / 60000);

        let differenceInUnits = {

            year: Math.floor(differenceMinutes / (60 * 24 * 365)),
            month: Math.floor(differenceMinutes / (60 * 24 * 30)),
            day: Math.floor(differenceMinutes / (60 * 24)),
            hour: Math.floor(differenceMinutes / 60),
            minute: differenceMinutes
        }

        let units = Object.keys(differenceInUnits);

        for(let unit of units) {

            let valueForUnit = differenceInUnits[unit];
            let unitString = unit + (valueForUnit > 1 ? 's' : '');

            if(valueForUnit > 0)
                return `${valueForUnit} ${unitString} ago`;
        }

        return 'just now';
    }

    static truncateUrl(url, maxLength) {

        return url.length < maxLength ? url : url.substr(0, maxLength) + '...'; 
    }
}

export default Helpers;