import Config from '../../config';
import EndGeometry from './geometry';
import EndModel from './model';

import { Helpers } from '../../helpers';
import { Miscellaneous } from '../../building_blocks/blocks';

class End {

    constructor(id, success, position) {
          
        if(!Helpers.isPoint(position))
            throw new Error(`'position' argument must be of type Point`);

        this._model = new EndModel(id, success);
        this._geometry = new EndGeometry(position);

        this._shouldUpdateGeometry = true;
    }

    draw(canvas) {

        if(this._shouldUpdateGeometry) {

            this.getGeometry().updateGeometry(this.getGeometry().getPosition());

            this._shouldUpdateGeometry = false;                    
        }


        Miscellaneous.drawLine(canvas, 
                               Config.Style.Links.Color, 
                               this.getGeometry().getInputPosition(), 
                               this.getGeometry().getTerminationPoint());

        Miscellaneous.drawInputAnchor(canvas, 
                                      Config.Style.Anchors.Color, 
                                      this.getGeometry().getInputPosition());

        Miscellaneous.drawTerminationSymbol(canvas,
                                            this.getModel().getSuccess(),
                                            this.getGeometry().getTerminationPoint());
    }

    invalidate() {

        this._shouldUpdateGeometry = true;
    }

    translate(dx, dy) {

        this.getGeometry().translate(dx, dy);
    }

    isAtLocation(x, y) {

        let position = this.getGeometry().getPosition();
        let size = this.getGeometry().getSize();

        return position.x - size.width / 2 < x && x < position.x + size.width / 2 &&
               position.y < y && y < position.y + size.height;
    }

    getInputPositionAtLocation(location, radius) {

        let inputPosition = this.getGeometry().getInputPosition();

        if(inputPosition.distanceTo(location) < radius)
            return inputPosition;
        else
            return null;
    }

    getOutputPositionAtLocation() {

        return null;
    }

    getOutputAtLocation() {

        return null;
    }

    getModel() {

        return this._model;
    }

    getGeometry() {

        return this._geometry;
    }

    exportElement() {

        return {

            id: this.getModel().getId(),
            type: 'end',
            success: this.getModel().getSuccess(),
            position: this.getGeometry().getPosition()
        };
    }

    static create(elementDescriptor) {

        return new End(elementDescriptor.id,
                       elementDescriptor.success, 
                       elementDescriptor.position);
    }
}

export default End;