import Config from '../../config';

import { Point, Size } from '../../helpers';

class EndGeometry {

    constructor(position) {

        this._position = new Point(position.x, position.y);
        this._size = new Size(0, 0);

        this._terminationPoint = new Point(0, 0);

        this._inputPosition = new Point(0, 0);
        this._outputPositions = [];
    }

    translate(dx, dy) {

        this._position.translate(dx, dy);
        this._inputPosition.translate(dx, dy);
        this._terminationPoint.translate(dx, dy);
    }

    updateGeometry(position) {

        let currentPosition = new Point(position.x, position.y);

        this._position.copyFrom(currentPosition);
        this._inputPosition.copyFrom(currentPosition);

        currentPosition.y += Config.Style.ChatItem.Spacing.Vertical + Config.Style.TerminationSymbol.Radius;
        this._terminationPoint.copyFrom(currentPosition);

        this._updateSize();
    }

    _updateSize() {
        
        this._size.width = 2 * Config.Style.TerminationSymbol.Radius;
        this._size.height = this._terminationPoint.y + Config.Style.TerminationSymbol.Radius - this._inputPosition.y;
    }

    getPosition() {

        return this._position;
    }

    getSize() {

        return this._size;
    }

    getInputPosition() {

        return this._inputPosition;
    }

    getOutputPositions() {

        return this._outputPositions;
    }

    getOutputPosition(index) {

        return this._outputPositions[index];
    }
    
    getOutputCount() {

        return this._outputPositions.length;
    }

    getTerminationPoint() {

        return this._terminationPoint;
    }
}

export default EndGeometry;