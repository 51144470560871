import ClassBuilder from '../../../../helpers/class_builder';
import Strings from '../../../../strings';
import React from 'react';
import './Measurement.css';

class Measurement extends React.Component {
    
    constructor(props) {

        super(props);

        this.state = {

            parameterValue: 0,
            buttonsDisabled: false,
            class: ClassBuilder.add('chat-item')
                               .add('rosie-measurement-item')
        };
    }

    onAcceptClick() {

        if(this.props.onResponse)
            this.props.onResponse(this.props.elementId, true, this.state.parameterValue);

        this.setState({

            buttonsDisabled: true
        });
    }

    onCancelClick() {

        if(this.props.onResponse)
            this.props.onResponse(this.props.elementId, false, null);

        this.setState({

            buttonsDisabled: true
        });
    }

    onParameterValueChanged(event) {

        this.setState({
            parameterValue: event.target.value
        });
    }

    componentDidMount() {

        this.props.onItemAdded();
        
        this.setState({

            class: this.state.class.add('animate')
        });
    }

    render() {
        return (

            <div className={this.state.class.serialize()}>
    
                <div className="measurement-title">{this.props.description + ' parameter simulation'}</div>
    
                <input className="measurement-value" type="text" value={this.state.parameterValue} onChange={this.onParameterValueChanged.bind(this)}/>
    
                <div className="button-holder">
    
                    <button className={this.state.buttonsDisabled ? 'disabled-button' : ''} onClick={this.onAcceptClick.bind(this)} disabled={this.state.buttonsDisabled}>{ Strings.getDefaultLanguage().SUBMIT_BUTTON }</button>
                    
                    <button className={this.state.buttonsDisabled ? 'disabled-button' : ''} onClick={this.onCancelClick.bind(this)} disabled={this.state.buttonsDisabled}>{ Strings.getDefaultLanguage().CANCEL_BUTTON }</button>
    
                </div>
            </div>
        );
    }
}

export default Measurement;