import Config from '../../config';
import Icons from '../../building_blocks/icons';

import VideoGeometry from './geometry';
import VideoModel from './model';

import { Helpers } from '../../helpers';
import { TextContainer, Miscellaneous } from '../../building_blocks/blocks';
import ActionContainer from '../../building_blocks/action_container';

class Video {

    constructor(id, position, videoId, title, responseList, outputs) {
     
        if(!Helpers.isPoint(position))
            throw new Error(`'position' argument must be of type Point`);

        this._model = new VideoModel(id, videoId, title, responseList, outputs);
        this._geometry = new VideoGeometry(position, responseList);

        this._shouldUpdateGeometry = true;
    }

    draw(canvas) {

        if(this._shouldUpdateGeometry) {

            this.getGeometry().updateGeometry(canvas, 
                                              Config.Style.Text.Font, 
                                              this.getGeometry().getPosition(), 
                                              this.getModel().getResponses(),
                                              this.getModel().getTitle());

            this._shouldUpdateGeometry = false;                    
        }
            
        Miscellaneous.drawInputAnchor(canvas, 
                                      Config.Style.Anchors.Color, 
                                      this.getGeometry().getInputPosition());

        Miscellaneous.drawLine(canvas, 
                               Config.Style.Links.Color, 
                               this.getGeometry().getInputPosition(),
                               this.getGeometry().getActionIconPosition());

        for(let i=0; i< this.getGeometry().getOutputCount(); i++) {

            Miscellaneous.drawVerticalLink(canvas, 
                                           Config.Style.Links.Color, 
                                           this.getGeometry().getBranchPosition(),
                                           this.getGeometry().getResponsePosition(i));

            Miscellaneous.drawLine(canvas, 
                                   Config.Style.Links.Color, 
                                   this.getGeometry().getResponsePosition(i),
                                   this.getGeometry().getOutputPosition(i));

            Miscellaneous.drawOutputAnchor(canvas, 
                                            Config.Style.Anchors.Color, 
                                            this.getGeometry().getOutputPosition(i));
        }

        ActionContainer.drawIconContainer(canvas, 
                                          Config.Style.ActionContainer.Title.Colors.Background, 
                                          Config.Style.Player.Colors.Background, 
                                          this.getGeometry().getActionIconPosition(), 
                                          Config.Style.ActionContainer.Size);

        Icons.drawPlayIcon(canvas, 
                           Config.Style.Player.Colors.Foreground, 
                           this.getGeometry().getActionIconPosition(), 
                           Config.Style.ActionContainer.Size / 2)                  

        TextContainer.drawTextContainer(canvas, 
                                        Config.Style.ActionContainer.Title.Colors.Background, 
                                        Config.Style.ActionContainer.Title.Colors.Foreground,
                                        TextContainer.ContainerType().Round,
                                        Config.Style.Text.Font, 
                                        this.getGeometry().getActionTitlePosition(), 
                                        this.getModel().getTitle());

        for(let i=0; i< this.getGeometry().getResponseCount(); i++) {

            TextContainer.drawTextContainer(canvas, 
                                            Config.Style.ChatItem.Colors.Computation.Background, 
                                            Config.Style.ChatItem.Colors.Computation.Foreground,
                                            TextContainer.ContainerType().Trapezoid,
                                            Config.Style.Text.Font, 
                                            this.getGeometry().getResponsePosition(i), 
                                            this.getModel().getResponse(i));
        }
    }

    invalidate() {

        this._shouldUpdateGeometry = true;
    }

    translate(dx, dy) {

        this.getGeometry().translate(dx, dy);
    }

    isAtLocation(x, y) {

        let position = this.getGeometry().getPosition();
        let size = this.getGeometry().getSize();

        return position.x - size.width / 2 < x && x < position.x + size.width / 2 &&
               position.y < y && y < position.y + size.height;
    }

    getInputPositionAtLocation(location, radius) {

        let inputPosition = this.getGeometry().getInputPosition();

        if(inputPosition.distanceTo(location) < radius)
            return inputPosition;
        else
            return null;
    }

    getOutputPositionAtLocation(location, radius) {

        let outputPositions = this.getGeometry().getOutputPositions();

        for(let outputPosition of outputPositions)
            if(outputPosition.distanceTo(location) < radius)
                return outputPosition;

        return null;
    }

    getOutputAtLocation(location, radius) {

        for(let i=0; i<this.getModel().getOutputCount(); i++)
            if(this.getGeometry().getOutputPosition(i).distanceTo(location) < radius)
                return i;

        return null;
    }

    getModel() {

        return this._model;
    }
    
    getGeometry() {

        return this._geometry;
    }

    exportElement() {

        return {

            id: this.getModel().getId(),
            type: 'video',
            position: this.getGeometry().getPosition(),
            videoId: this.getModel().getVideoId(),
            title: this.getModel().getTitle(),
            responseList: this.getModel().getResponses(),
            outputs: this.getModel().getOutputs()
        };
    }

    static create(elementDescriptor) {

        return new Video(elementDescriptor.id, 
                         elementDescriptor.position,
                         elementDescriptor.videoId,
                         elementDescriptor.title,
                         elementDescriptor.responseList,
                         elementDescriptor.outputs);
    }
}

export default Video;