import { Helpers } from "../../helpers";

class ComparatorModel {

    constructor(id, variable, brakepoints, outputs) {

        if(!Helpers.isInteger(id))
            throw new Error(`'id' argument must be an Integer`);
            
        this._id = id;

        this.setVariable(variable);
        this.setBrakepoints(brakepoints);
        this.setOutputs(outputs);
    }

    export() {

        return {

            id: this._id,
            type: 'comparator',
            followups: this._exportFollowups()
        };
    }

    _exportFollowups() {

        let followups = [];
        let conditionStatements = this.getConditionStatements();

        for(let i=0; i< conditionStatements.length; i++) {

            followups.push({

                statement: conditionStatements[i],
                followup: this._outputs[i]
            });
        }

        return followups;
    }

    isId(id) {

        return this._id === id;
    }

    getId() {

        return this._id;
    }

    getVariable() {

        return this._variable;
    }

    setVariable(value) {

        this._variable = value;
    }

    getOutputs() {

        return this._outputs;
    }
    
    getOutput(index) {

        return this._outputs[index];
    }    

    getOutputCount() {

        return this._outputs.length;
    }

    setOutputs(outputs) {

        if(Helpers.isDefined(outputs))
            if(!Helpers.isArray(outputs) || outputs.length !== this._brakepoints.length + 1)
                throw new Error(`'outputs' argument must be an Array of length 'brakepoints' + 1`);

        if(Helpers.isDefined(outputs)) {

            this._outputs = outputs;
        }
        else 
        if(Helpers.isUndefined(this._outputs) || this._outputs.length !== (this._brakepoints.length + 1)) {

            this._outputs = [];

            for(let i=0; i<this._brakepoints.length + 1; i++)
                this._outputs.push(null);
        }
    }

    setOutput(index, value) {

        if(!Helpers.isIndexInBounds(index, this._outputs.length))
            throw new Error(`Index out of bounds`);

            if(!(Helpers.isInteger(value) || value === null))
            throw new Error(`'value' argument must be an Integer or null`);

        this._outputs[index] = value;
    }

    getBrakepoints() {

        return this._brakepoints;
    }
    
    getBrakepoint(index) {

        return this._brakepoints[index];
    }    

    getBrakepointCount() {

        return this._brakepoints.length;
    }

    setBrakepoints(brakepoints) {

        if(brakepoints.length < 1)
            throw new Error(`Comparator must have at least 1 brakepoint`);

        this._brakepoints = brakepoints;

        if(Helpers.isUndefined(this._outputs) || this._outputs.length !== brakepoints.length + 1) {

            this._outputs = [];

            for(let i=0; i<this._brakepoints.length + 1; i++)
                this._outputs.push(null);
        }
    }

    setBrakepoint(index, value) {

        if(!Helpers.isIndexInBounds(index, this._brakepoints.length))
            throw new Error(`Index out of bounds`);

        this._brakepoints[index] = value;
    }

    addBrakepoint(brakepoint, position) {

        if(Helpers.isUndefined(position)) {

            this._brakepoints.push(brakepoint);
            this._outputs.push(null);
        }
        else {

            if(!Helpers.isIndexInBounds(position, this._brakepoints.length))
                throw new Error(`Index out of bounds`);

            this._brakepoints.splice(position, 0, brakepoint);
            this._outputs.splice(position, 0, null);
        }
    }

    deleteBrakepoint(index) {

        if(this._brakepoints.length <= 2)
            throw new Error(`Comparator must have at least 2 brakepoints`);

        if(!Helpers.isIndexInBounds(index, this._brakepoints.length))
            throw new Error(`Index out of bounds`);

        this._brakepoints.splice(index, 1);
        this._outputs.splice(index, 1);
    }

    getConditionTexts() {

        let variable = this.getVariable();
        let brakepoints = this.getBrakepoints().sort((a, b) => a-b);

        let conditionTextList = [];

        conditionTextList.push(`${variable} ≤ ${brakepoints[0]}`);

        if(brakepoints.length > 1)
            for(let i=0; i<brakepoints.length-1; i++)
                conditionTextList.push(`${brakepoints[i]} < ${variable} ≤ ${brakepoints[i+1]}`);
        
        conditionTextList.push(`${brakepoints[brakepoints.length - 1]} < ${variable}`);

        return conditionTextList;
    }

    getConditionStatements() {

        let variable = this.getVariable();
        let brakepoints = this.getBrakepoints().sort((a, b) => a-b);

        let conditionTextList = [];

        conditionTextList.push(`${variable} <= ${brakepoints[0]}`);

        if(brakepoints.length > 1)
            for(let i=0; i<brakepoints.length-1; i++)
                conditionTextList.push(`${brakepoints[i]} < ${variable} && ${variable} <= ${brakepoints[i+1]}`);
        
        conditionTextList.push(`${brakepoints[brakepoints.length - 1]} < ${variable}`);

        return conditionTextList;
    }
}

export default ComparatorModel;