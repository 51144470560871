import Config from '../../config';
import ActionContainer from '../../building_blocks/action_container';
import Icons from '../../building_blocks/icons';

import InputGeometry from './geometry';
import InputModel from './model';

import { Helpers } from '../../helpers';
import { TextContainer, Miscellaneous } from '../../building_blocks/blocks';

class Input {

    constructor(id, position, name, description, responseList, outputs) {
     
        if(!Helpers.isPoint(position))
            throw new Error(`'position' argument must be of type Point`);

        this._model = new InputModel(id, name, description, responseList, outputs);
        this._geometry = new InputGeometry(position, responseList);

        this._shouldUpdateGeometry = true;

        this.imageReady = false;
        this.img = new Image();
        this.img.src = 'data:image/svg+xml;base64,' + btoa('<svg xmlns="http://www.w3.org/2000/svg" width="401.59" height="418.97" viewBox="0 0 401.59 418.97"><title>a</title><polygon points="200.8 0.58 372.45 105.03 372.45 313.94 200.8 418.39 29.14 313.94 29.14 105.03 200.8 0.58" fill="#fff" stroke="#373535" stroke-miterlimit="10"/><polygon points="53.08 61.77 248.32 14.25 396.03 161.97 348.51 357.2 153.28 404.72 5.56 257 53.08 61.77" fill="#337bbe" stroke="#373535" stroke-miterlimit="10"/><polygon points="107.58 22.54 307.8 39.41 401.02 226.36 294.02 396.43 93.8 379.56 0.57 192.61 107.58 22.54" fill="#ed2790" stroke="#373535" stroke-miterlimit="10"/><polygon points="200.8 0.58 372.45 105.03 372.45 313.94 200.8 418.39 29.14 313.94 29.14 105.03 200.8 0.58" fill="#fff215" stroke="#373535" stroke-miterlimit="10"/></svg>');
    
        this.img.onload = ()=> { this.imageReady = true; };
    }

    draw(canvas) {

        if(this._shouldUpdateGeometry) {

            this.getGeometry().updateGeometry(canvas, 
                                              Config.Style.Text.Font, 
                                              this.getGeometry().getPosition(), 
                                              this.getModel().getResponses(),
                                              this.getModel().getDescription());

            this._shouldUpdateGeometry = false;                    
        }
            
        Miscellaneous.drawInputAnchor(canvas, 
                                      Config.Style.Anchors.Color, 
                                      this.getGeometry().getInputPosition());

        Miscellaneous.drawLine(canvas, 
                               Config.Style.Links.Color, 
                               this.getGeometry().getInputPosition(),
                               this.getGeometry().getActionIconPosition());

        for(let i=0; i< this.getGeometry().getOutputCount(); i++) {

            Miscellaneous.drawVerticalLink(canvas, 
                                           Config.Style.Links.Color, 
                                           this.getGeometry().getBranchPosition(),
                                           this.getGeometry().getResponsePosition(i));

            Miscellaneous.drawLine(canvas, 
                                   Config.Style.Links.Color, 
                                   this.getGeometry().getResponsePosition(i),
                                   this.getGeometry().getOutputPosition(i));

            Miscellaneous.drawOutputAnchor(canvas, 
                                            Config.Style.Anchors.Color, 
                                            this.getGeometry().getOutputPosition(i));
        }

        ActionContainer.drawIconContainer(canvas, 
                                          Config.Style.ActionContainer.Title.Colors.Background, 
                                          Config.Style.Input.Colors.Background, 
                                          this.getGeometry().getActionIconPosition(), 
                                          Config.Style.ActionContainer.Size);

        Icons.drawInputIcon(canvas, 
                            Config.Style.Input.Colors.Foreground,  
                            this.getGeometry().getActionIconPosition(), 
                            Config.Style.ActionContainer.Size / 2.2)                 

        TextContainer.drawTextContainer(canvas, 
                                        Config.Style.ActionContainer.Title.Colors.Background, 
                                        Config.Style.ActionContainer.Title.Colors.Foreground,
                                        TextContainer.ContainerType().Round,
                                        Config.Style.Text.Font, 
                                        this.getGeometry().getActionTitlePosition(), 
                                        this.getModel().getDescription());

        for(let i=0; i< this.getGeometry().getResponseCount(); i++) {

            TextContainer.drawTextContainer(canvas, 
                                            Config.Style.ChatItem.Colors.Computation.Background, 
                                            Config.Style.ChatItem.Colors.Computation.Foreground,
                                            TextContainer.ContainerType().Trapezoid,
                                            Config.Style.Text.Font, 
                                            this.getGeometry().getResponsePosition(i), 
                                            this.getModel().getResponse(i));
        }
    }

    invalidate() {

        this._shouldUpdateGeometry = true;
    }

    translate(dx, dy) {

        this.getGeometry().translate(dx, dy);
    }

    isAtLocation(x, y) {

        let position = this.getGeometry().getPosition();
        let size = this.getGeometry().getSize();

        return position.x - size.width / 2 < x && x < position.x + size.width / 2 &&
               position.y < y && y < position.y + size.height;
    }

    getInputPositionAtLocation(location, radius) {

        let inputPosition = this.getGeometry().getInputPosition();

        if(inputPosition.distanceTo(location) < radius)
            return inputPosition;
        else
            return null;
    }

    getOutputPositionAtLocation(location, radius) {

        let outputPositions = this.getGeometry().getOutputPositions();

        for(let outputPosition of outputPositions)
            if(outputPosition.distanceTo(location) < radius)
                return outputPosition;

        return null;
    }

    getOutputAtLocation(location, radius) {

        for(let i=0; i<this.getModel().getOutputCount(); i++)
            if(this.getGeometry().getOutputPosition(i).distanceTo(location) < radius)
                return i;

        return null;
    }

    getModel() {

        return this._model;
    }
    
    getGeometry() {

        return this._geometry;
    }

    exportElement() {

        return {

            id: this.getModel().getId(),
            type: 'input',
            position: this.getGeometry().getPosition(),
            name: this.getModel().getName(),
            description: this.getModel().getDescription(),
            responseList: this.getModel().getResponses(),
            outputs: this.getModel().getOutputs()
        };
    }

    static create(elementDescriptor) {

        return new Input(elementDescriptor.id, 
                         elementDescriptor.position,
                         elementDescriptor.name,
                         elementDescriptor.description,
                         elementDescriptor.responseList,
                         elementDescriptor.outputs);
    }
}

export default Input;