import Config from '../../config';
import TextContainer from '../../building_blocks/text_container';

import { Point, Size } from '../../helpers';

class OutputGeometry {

    constructor(position, textLines) {

        this._position = new Point(position.x, position.y);
        this._size = new Size(0, 0);

        this._textLineContainerHeight = 0;
        this._inputPosition = new Point(0, 0);
        this._outputPositions = [ new Point(0, 0) ];

        this._textLinePositions = [];
        
        for(let i=0; i<textLines.length; i++)
            this._textLinePositions.push(new Point(0, 0));
    }

    translate(dx, dy) {

        this._position.translate(dx, dy);
        this._inputPosition.translate(dx, dy);

        for(let textLinePosition of this._textLinePositions)
            textLinePosition.translate(dx, dy);

        this._outputPositions[0].translate(dx, dy);
    }

    updateGeometry(canvas, font, position, textLines) {

        let currentPosition = new Point(position.x, position.y);

        if(textLines.length !== this._textLinePositions.length) {
            
            this._textLinePositions = [];

            for(let i=0; i<textLines.length; i++)
                this._textLinePositions.push(new Point(0, 0));

            this._size.width = 0;
        }

        this._position.copyFrom(currentPosition);
        this._inputPosition.copyFrom(currentPosition);

        this._textLineContainerHeight = TextContainer.getTextContainerHeight(font);

        currentPosition.y += Config.Style.ChatItem.Spacing.Vertical;

        for(let i=0; i<this._textLinePositions.length; i++) {

            this._textLinePositions[i].copyFrom(currentPosition);

            if(i < this._textLinePositions.length - 1)
                currentPosition.y += this._textLineContainerHeight + Config.Style.ChatItem.Spacing.TextLine;
        }

        currentPosition.y += this._textLineContainerHeight + Config.Style.ChatItem.Spacing.Vertical + Config.Style.Anchors.Size / 2;
        this._outputPositions[0].copyFrom(currentPosition);

        this._updateSize(canvas, font, textLines);
    }

    _updateSize(canvas, font, textLines) {
        
        this._size.width = 0;
        this._size.height = this._outputPositions[0].y - this._inputPosition.y;

        for(let textLine of textLines) {
            
            let currentTextContainerWidth = TextContainer.getTextContainerWidth(canvas, 
                                                                                font, 
                                                                                this._textLineContainerHeight, 
                                                                                textLine);

            if(currentTextContainerWidth > this._size.width)
                this._size.width = currentTextContainerWidth;
        }
    }

    getPosition() {

        return this._position;
    }

    getSize() {

        return this._size;
    }

    getInputPosition() {

        return this._inputPosition;
    }

    getOutputPositions() {

        return this._outputPositions;
    }

    getOutputPosition(index) {

        return this._outputPositions[index];
    }
    
    getOutputCount() {

        return this._outputPositions.length;
    }

    getTextLinePositions() {

        return this._textLinePositions;
    }

    getTextLinePosition(index) {

        return this._textLinePositions[index];
    }
    
    getTextLineCount() {

        return this._textLinePositions.length;
    }
}

export default OutputGeometry;