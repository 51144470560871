import React from 'react';
import Simulator from '../simulator/container/Simulator';
import Strings from '../../strings';
import Config from '../../config';
import './App.css';
import API from '../../api/api';
import FlowDocument from '../../editor/flow_document';
import ReactGA from 'react-ga';
class App extends React.Component {

    constructor(props) {

        super(props);

        this.state = { 

            appBody: null,
            popupContainer: null,
            dialogContainer: null,
            loadingText: Strings.getDefaultLanguage().LOADING_RESOURCES,
            changesSaved: true
        };
    }

    componentDidMount() {
        
        const lang = this.getParameterByName('lang');
        const type = this.getParameterByName('type');
        const submit_url = this.getParameterByName('submit_url');
        const tid = this.getParameterByName('tid');
        const cid = this.getParameterByName('cid');

        Strings.setSubmitUrl(submit_url);
        Strings.setTopicId(tid);
        Strings.setConversationId(cid);

        if(lang === 'sq')
            Strings.setDefaultLanguage(Strings.AL);

        if(lang === 'sr')
            Strings.setDefaultLanguage(Strings.SR);

        const simulationParameters = this.getSimulationParameters();
        this.initSimulationApp(simulationParameters.topicId, simulationParameters.conversationId, lang, type);
    }

    getSimulationParameters() {

        const requestedUrl = new URL(window.location.href);
        
        let topicId = requestedUrl.searchParams.get("topic_id");
        let conversationId = requestedUrl.searchParams.get("conversation_id");

        return {

            isSimulationRequest: topicId !== null && conversationId !== null,
            topicId,
            conversationId,
        };
    }
    getParameterByName(name, url) {
        if (!url) url = window.location.href;
        // eslint-disable-next-line
        name = name.replace(/[\[\]]/g, '\\$&');
        var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    }
    initSimulationApp(topicId, conversationId, lang, type) {

        this.simulatorRef = React.createRef();
        this.topicId = topicId;
        this.conversationId = conversationId;

        this.setState({ 

            appBody: <Simulator ref={this.simulatorRef} isSimulationApp={true} />
        });

        API.get(Config.RESOURCES_URL, "")
        .then((resources) => {

            window.Resources = resources.response;
            this.setState({loadingText: Strings.getDefaultLanguage().LOADING_CONVERSATION});

            const mURL = `https://rcb.rosie.health/api/topic/${window.topicId}/conversation/${window.conversationId}`;
            
            API.get(mURL, "")
            .then(conversationData => {

                this.setState({loadingText: null});

                let conversationDocument = JSON.parse(conversationData.response.document);

                let loadedConversationDocument = new FlowDocument();
                loadedConversationDocument.import(conversationDocument);

                this.simulatorRef.current.startConversation(loadedConversationDocument);
            })
            .catch((error) => {

                this.setState({loadingText: Strings.getDefaultLanguage().FAILED_LOADING_CONVERSATION});
                console.log(error);
            });
        })
        .catch((error) => {

            this.setState({loadingText: Strings.getDefaultLanguage().FAILED_LOADING_RESOURCES});
            console.log(error);
        });
    }

    render() {

        let loadingFailed = String(this.state.loadingText).toLowerCase().indexOf('failed') !== -1;

        return (
            <div className="app-container">
                { this.state.dialogContainer }

                { this.state.popupContainer }

                { this.state.appBody }
                
                <div className={ "app-loading-container" + (this.state.loadingText ? '' : ' hidden') }>
                    <img alt="" src={ require('../../images/loading.gif') } className={"app-loading-loader" + (loadingFailed ? ' hidden' : '')}/>  
                    <div className="app-loading-text">{ this.state.loadingText }</div>
                </div>

            </div>
        );
    }
}

export default App;