import { Helpers } from "../../helpers";

class StatementModel {

    constructor(id, variable, value, outputs) {

        if(!Helpers.isInteger(id))
            throw new Error(`'id' argument must be an Integer`);
            
        this._id = id;

        this.setVariable(variable);
        this.setValue(value);
        this.setOutputs(outputs);
    }

    export() {

        return {

            id: this._id,
            type: 'statement',
            statement: this.getStatement(),
            followup: this._outputs[0]
        };
    }

    isId(id) {

        return this._id === id;
    }

    getId() {

        return this._id;
    }

    getVariable() {

        return this._variable;
    }

    setVariable(value) {

        this._variable = value;
    }

    getValue() {

        return this._value;
    }

    setValue(value) {

        this._value = value;
    }

    getStatement() {

        return `${this._variable} = ${this._value}`;
    }

    getOutputs() {

        return this._outputs;
    }
    
    getOutput(index) {

        return this._outputs[index];
    }    

    getOutputCount() {

        return this._outputs.length;
    }

    setOutputs(outputs) {

        if(Helpers.isDefined(outputs))
            if(!Helpers.isArray(outputs) || outputs.length !== 1)
                throw new Error(`'outputs' argument must be an Array of 1`);

        if(Helpers.isDefined(outputs))
            this._outputs = outputs;
        else
            this._outputs = [ null ];
    }

    setOutput(index, value) {

        if(!Helpers.isIndexInBounds(index, this._outputs.length))
            throw new Error(`Index out of bounds`);
        
        if(!(Helpers.isInteger(value) || value === null))
            throw new Error(`'value' argument must be an Integer or null`);

        this._outputs[index] = value;
    }
}

export default StatementModel;