import ClassBuilder from '../../../../helpers/class_builder';
import React from 'react';

import './Image.css';

class Image extends React.Component {
    
    constructor(props) {

        super(props);

        this.state = {

            class: ClassBuilder.add('chat-item')
                               .add('rosie-video-item')
        };

        setTimeout(() => {

            this.props.onResponse(this.props.elementId, true);
        },
        700);
    }

    componentDidMount() {

        this.props.onItemAdded();

        this.setState({

            class: this.state.class.add('animate')
        });
    }

    getImageResourceData(resourceId) {

        let imageResourceList = window.Resources.find(resource => resource.type === 'image');
    
        if(typeof imageResourceList === 'undefined')
            return null;

        return imageResourceList.instances.find(resource => resource._id === resourceId);
    }

    render() {

        let resourceData = this.getImageResourceData(this.props.resourceId);

        return (

            <div className={this.state.class.serialize()}>

                <img alt="" className="image-placeholder" src={ resourceData.uri }/>

            </div>
        );
    }
}

export default Image;