import Config from '../../config';
import StatementGeometry from './geometry';
import StatementModel from './model';

import { Helpers } from '../../helpers';
import { TextContainer, Miscellaneous } from '../../building_blocks/blocks';

class Statement {

    constructor(id, position, variable, value, outputs) {

        if(!Helpers.isPoint(position))
            throw new Error(`'position' argument must be of type Point`);

        this._model = new StatementModel(id, variable, value, outputs);
        this._geometry = new StatementGeometry(position);

        this._shouldUpdateGeometry = true;
    }

    draw(canvas) {

        if(this._shouldUpdateGeometry) {

            this.getGeometry().updateGeometry(canvas, 
                                              Config.Style.Text.Font, 
                                              this.getGeometry().getPosition(), 
                                              this.getModel().getStatement());

            this._shouldUpdateGeometry = false;                    
        }


        Miscellaneous.drawLine(canvas, 
                               Config.Style.Links.Color, 
                               this.getGeometry().getInputPosition(), 
                               this.getGeometry().getOutputPosition(0));

        Miscellaneous.drawInputAnchor(canvas, 
                                      Config.Style.Anchors.Color, 
                                      this.getGeometry().getInputPosition());

        Miscellaneous.drawOutputAnchor(canvas, 
                                       Config.Style.Anchors.Color, 
                                       this.getGeometry().getOutputPosition(0));

        TextContainer.drawTextContainer(canvas, 
                                        Config.Style.ChatItem.Colors.Computation.Background, 
                                        Config.Style.ChatItem.Colors.Computation.Foreground,
                                        TextContainer.ContainerType().Trapezoid,
                                        Config.Style.Text.Font, 
                                        this.getGeometry().getStatementPosition(), 
                                        this.getModel().getStatement());
    }

    invalidate() {

        this._shouldUpdateGeometry = true;
    }

    translate(dx, dy) {

        this.getGeometry().translate(dx, dy);
    }

    isAtLocation(x, y) {

        let position = this.getGeometry().getPosition();
        let size = this.getGeometry().getSize();

        return position.x - size.width / 2 < x && x < position.x + size.width / 2 &&
               position.y < y && y < position.y + size.height;
    }

    getInputPositionAtLocation(location, radius) {

        let inputPosition = this.getGeometry().getInputPosition();

        if(inputPosition.distanceTo(location) < radius)
            return inputPosition;
        else
            return null;
    }

    getOutputPositionAtLocation(location, radius) {

        let outputPositions = this.getGeometry().getOutputPositions();

        for(let outputPosition of outputPositions)
            if(outputPosition.distanceTo(location) < radius)
                return outputPosition;

        return null;
    }

    getOutputAtLocation(location, radius) {

        for(let i=0; i<this.getModel().getOutputCount(); i++)
            if(this.getGeometry().getOutputPosition(i).distanceTo(location) < radius)
                return i;

        return null;
    }

    getModel() {

        return this._model;
    }

    getGeometry() {

        return this._geometry;
    }

    exportElement() {

        return {

            id: this.getModel().getId(),
            type: 'statement',
            position: this.getGeometry().getPosition(),
            variable: this.getModel().getVariable(),
            value: this.getModel().getValue(),
            outputs: this.getModel().getOutputs()
        };
    }

    static create(elementDescriptor) {

        return new Statement(elementDescriptor.id, 
                             elementDescriptor.position,
                             elementDescriptor.variable,
                             elementDescriptor.value,
                             elementDescriptor.outputs);
    }
}

export default Statement;