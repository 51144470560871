import { Helpers } from "../../helpers";

class OutputModel {

    constructor(id, textLineVariations, outputs) {

        if(!Helpers.isInteger(id))
            throw new Error(`'id' argument must be an Integer`);
            
        this._id = id;

        this.setTextLineVariations(textLineVariations);
        this.setOutputs(outputs);
    }

    export() {

        return {

            id: this._id,
            type: 'output',
            textLineVariations: this._textLineVariations,
            followup: this._outputs[0]
        };
    }

    isId(id) {

        return this._id === id;
    }

    getId() {

        return this._id;
    }

    getTextLineVariations() {

        return this._textLineVariations;
    }

    setTextLineVariations(textLineVariations) {
    
        if(!(textLineVariations instanceof Array))
            throw new Error(`Argument must be an array of String arrays`);

        for(let textLines of textLineVariations)
            if(!(textLines instanceof Array))
                throw new Error(`Argument must be an array of String arrays`);

        this._textLineVariations = textLineVariations;
    }

    getTextLineVariationCount() {

        return this._textLineVariations.length;
    }

    getTextLines(variationIndex) {

        return this._textLineVariations[variationIndex];
    }

    getTextLine(variationIndex, lineIndex) {

        return this._textLineVariations[variationIndex][lineIndex];
    }

    getTextLineCount(variationIndex) {

        return this._textLineVariations[variationIndex].length;
    }

    setTextLines(variationIndex, textLines) {

        if(!Helpers.isIndexInBounds(variationIndex, this._textLineVariations.length))
            throw new Error(`Index out of bounds`);

        if(!Helpers.isArray(textLines))
            throw new Error(`'textLines' argument must be an array of Strings`);

        for(let textLine of textLines)
            if(!Helpers.isString(textLine))
                throw new Error(`'textLines' argument elements must be Strings`);

        this._textLineVariations[variationIndex] = textLines;
    }

    setTextLine(variationIndex, lineIndex, textLine) {

        if(!Helpers.isIndexInBounds(variationIndex, this._textLineVariations.length))
            throw new Error(`Variation Index out of bounds`);

        if(!Helpers.isIndexInBounds(lineIndex, this._textLineVariations[variationIndex].length))
            throw new Error(`Text line Index out of bounds`);

        if(!Helpers.isString(textLine))
            throw new Error(`'textLines' argument elements must be Strings`);

        this._textLineVariations[variationIndex][lineIndex] = textLine;
    }

    addTextLine(variationIndex, textLine, position) {

        if(Helpers.isUndefined(position)) {

            this._textLineVariations[variationIndex].push(textLine);
        }
        else {

            if(!Helpers.isIndexInBounds(position, this._textLineVariations.length))
                throw new Error(`Index out of bounds`);

            this._textLineVariations[variationIndex].splice(position, 0, textLine);
        }
    }

    deleteTextLine(variationIndex, lineIndex) {
        
        if(this._textLineVariations[variationIndex].length <= 1)
            throw new Error(`Output element must have at least 1 text line`);

        if(!Helpers.isIndexInBounds(lineIndex, this._textLineVariations[variationIndex].length))
            throw new Error(`Index out of bounds`);

        this._textLineVariations[variationIndex].splice(lineIndex, 1);
    }

    setPrimaryTextLines(index) {

        if(!Helpers.isIndexInBounds(index, this._textLineVariations.length))
            throw new Error(`Index out of bounds`);

        let tmpTextLines = this._textLineVariations[index];

        this._textLineVariations[index] = this._textLineVariations[0];
        this._textLineVariations[0] = tmpTextLines;
    }

    getOutputs() {

        return this._outputs;
    }
    
    getOutput(index) {

        return this._outputs[index];
    }    

    getOutputCount() {

        return this._outputs.length;
    }

    setOutputs(outputs) {

        if(Helpers.isDefined(outputs))
            if(!Helpers.isArray(outputs) || outputs.length !== 1)
                throw new Error(`'outputs' argument must be an Array of 1`);

        if(Helpers.isDefined(outputs))
            this._outputs = outputs;
        else
            this._outputs = [ null ];
    }

    setOutput(index, value) {

        if(!Helpers.isIndexInBounds(index, this._outputs.length))
            throw new Error(`Index out of bounds`);
        
        if(!(Helpers.isInteger(value) || value === null))
            throw new Error(`'value' argument must be an Integer or null`);

        this._outputs[index] = value;
    }

    clone() {

        return new OutputModel(this._id, 
                               this._textLineVariations, 
                               this._outputs);
    }
}

export default OutputModel;