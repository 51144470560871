import { Helpers } from '../../helpers';

class AlertModel {

    constructor(id, target, mode, message, title, outputs) {

        if(!Helpers.isInteger(id))
            throw new Error(`'id' argument must be an Integer`);
            
        this._id = id;

        this.setTarget(target);
        this.setMode(mode);
        this.setMessage(message);
        this.setTitle(title);
        this.setOutputs(outputs);
    }

    export() {

        return {

            id: this._id,
            type: 'alert',
            target: this._target,
            mode: this._mode,
            followup: this._outputs[0]
        };
    }

    isId(id) {

        return this._id === id;
    }

    getId() {

        return this._id;
    }

    getTarget() {

        return this._target;
    }
    
    setTarget(target) {

        if(!Helpers.isString(target))
            throw new Error(`'target' argument must be a String`);

        this._target = target;
    }

    getMode() {

        return this._mode;
    }
    
    setMode(mode) {

        if(!Helpers.isString(mode))
            throw new Error(`'mode' argument must be a String`);

        this._mode = mode;
    }

    getMessage() {

        return this._message;
    }
    
    setMessage(message) {

        if(!Helpers.isString(message))
            throw new Error(`'message' argument must be a String`);

        this._message = message;
    }

    getTitle() {

        return this._title;
    }
    
    setTitle(title) {

        if(!Helpers.isString(title))
            throw new Error(`'title' argument must be a String`);

        this._title = title;
    }

    getOutputs() {

        return this._outputs;
    }
    
    getOutput(index) {

        return this._outputs[index];
    }    

    getOutputCount() {

        return this._outputs.length;
    }

    setOutputs(outputs) {

        if(Helpers.isDefined(outputs))
            if(!Helpers.isArray(outputs) || outputs.length !== 1)
                throw new Error(`'outputs' argument must be an Array of 1`);

        if(Helpers.isDefined(outputs))
            this._outputs = outputs;
        else
            this._outputs = [ null ];
    }

    setOutput(index, value) {

        if(!Helpers.isIndexInBounds(index, this._outputs.length))
            throw new Error(`Index out of bounds`);
    
        if(!(Helpers.isInteger(value) || value === null))
            throw new Error(`'value' argument must be an Integer or null`);

        this._outputs[index] = value;
    }
}

export default AlertModel;