import Config from '../../config';
import ComparatorGeometry from './geometry';
import ComparatorModel from './model';

import { Helpers } from '../../helpers';
import { TextContainer, Miscellaneous } from '../../building_blocks/blocks';

class Comparator {

    constructor(id, position, variable, brakepoints, outputs) {
     
        if(!Helpers.isPoint(position))
            throw new Error(`'position' argument must be of type Point`);

        this._model = new ComparatorModel(id, variable, brakepoints, outputs);
        this._geometry = new ComparatorGeometry(position, this.getModel().getConditionTexts());

        this._shouldUpdateGeometry = true;
    }

    draw(canvas) {

        if(this._shouldUpdateGeometry) {

            this.getGeometry().updateGeometry(canvas, 
                                              Config.Style.Text.Font, 
                                              this.getGeometry().getPosition(), 
                                              this.getModel().getConditionTexts());

            this._shouldUpdateGeometry = false;                    
        }
            
        Miscellaneous.drawInputAnchor(canvas, 
                                      Config.Style.Anchors.Color, 
                                      this.getGeometry().getInputPosition());

        
        for(let i=0; i< this.getGeometry().getOutputCount(); i++) {

            Miscellaneous.drawVerticalLink(canvas, 
                                           Config.Style.Links.Color, 
                                           this.getGeometry().getInputPosition(),
                                           this.getGeometry().getResponsePosition(i));

            Miscellaneous.drawLine(canvas, 
                                   Config.Style.Links.Color, 
                                   this.getGeometry().getResponsePosition(i),
                                   this.getGeometry().getOutputPosition(i));

            Miscellaneous.drawOutputAnchor(canvas, 
                                            Config.Style.Anchors.Color, 
                                            this.getGeometry().getOutputPosition(i));
        }

        for(let i=0; i< this.getGeometry().getResponseCount(); i++) {

            TextContainer.drawTextContainer(canvas, 
                                            Config.Style.ChatItem.Colors.Computation.Background, 
                                            Config.Style.ChatItem.Colors.Computation.Foreground,
                                            TextContainer.ContainerType().Trapezoid,
                                            Config.Style.Text.Font, 
                                            this.getGeometry().getResponsePosition(i), 
                                            this.getModel().getConditionTexts()[i]);
        }
    }

    invalidate() {

        this._shouldUpdateGeometry = true;
    }

    translate(dx, dy) {

        this.getGeometry().translate(dx, dy);
    }

    isAtLocation(x, y) {

        let position = this.getGeometry().getPosition();
        let size = this.getGeometry().getSize();

        return position.x - size.width / 2 < x && x < position.x + size.width / 2 &&
               position.y < y && y < position.y + size.height;
    }

    getInputPositionAtLocation(location, radius) {

        let inputPosition = this.getGeometry().getInputPosition();

        if(inputPosition.distanceTo(location) < radius)
            return inputPosition;
        else
            return null;
    }

    getOutputPositionAtLocation(location, radius) {

        let outputPositions = this.getGeometry().getOutputPositions();

        for(let outputPosition of outputPositions)
            if(outputPosition.distanceTo(location) < radius)
                return outputPosition;

        return null;
    }

    getOutputAtLocation(location, radius) {

        for(let i=0; i<this.getModel().getOutputCount(); i++)
            if(this.getGeometry().getOutputPosition(i).distanceTo(location) < radius)
                return i;

        return null;
    }

    getModel() {

        return this._model;
    }
    
    getGeometry() {

        return this._geometry;
    }

    exportElement() {

        return {

            id: this.getModel().getId(),
            type: 'comparator',
            position: this.getGeometry().getPosition(),
            variable: this.getModel().getVariable(),
            brakepoints: this.getModel().getBrakepoints(),
            outputs: this.getModel().getOutputs()
        };
    }

    static create(elementDescriptor) {

        return new Comparator(elementDescriptor.id, 
                              elementDescriptor.position, 
                              elementDescriptor.variable,
                              elementDescriptor.brakepoints,
                              elementDescriptor.outputs);
    }
}

export default Comparator;