import ClassBuilder from '../../../../helpers/class_builder';
import React from 'react';

import './OutputItem.css';

class TypingItem extends React.Component {
    
    constructor(props) {

        super(props);

        this.state = {

            text: props.text,
            class: ClassBuilder.add('chat-item')
                               .add('rosie-chat-item')
        }
    }

    componentDidMount() {
        
        this.props.onItemAdded();
        setTimeout(() => {

            this.setState({

                class: this.state.class.add('animate')
            })
        }, 100);
    }

    render() {
        return (

            <div className={this.state.class.serialize()}>
                <img className="rosie-chat-typing-icon" alt="" src={require("../../../../images/typing_loader.gif")}></img>
            </div>
        );
    }
}

export default TypingItem;