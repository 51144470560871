import Config from '../config';
import TextContainer from './text_container';

class ResponseArray {

    static getResponseArrayPositions(canvas, font, position, responseList) {

        let totalWidth = 0;
        let responsePositions = [];

        let responseWidths = [];
        let responseHeight = TextContainer.getTextContainerHeight(font);
        
        let responseSpacing = Config.Style.ChatItem.Spacing.Horizontal;

        for(let response of responseList) {

            let responseWidth = TextContainer.getTextContainerWidth(canvas, 
                                                                    font, 
                                                                    responseHeight, 
                                                                    response)

            responseWidths.push(responseWidth);
        }

        for(let responseWidth of responseWidths)
            totalWidth += responseWidth;

        totalWidth += responseSpacing * (responseWidths.length - 1);

        let currentResponsePosition = position.x - totalWidth / 2;

        for(let i=0; i<responseWidths.length; i++) {

            responsePositions[i] = currentResponsePosition + responseWidths[i] / 2;
            currentResponsePosition += responseWidths[i] + responseSpacing;
        }

        return responsePositions;
    }

    static getResponseArrayWidth(canvas, font, responseList) {

        let totalWidth = 0;

        let responseWidths = [];
        let responseHeight = TextContainer.getTextContainerHeight(font);
        
        let responseSpacing = Config.Style.ChatItem.Spacing.Horizontal;

        for(let response of responseList) {

            let responseWidth = TextContainer.getTextContainerWidth(canvas, 
                                                                    font, 
                                                                    responseHeight, 
                                                                    response)

            responseWidths.push(responseWidth);
        }

        for(let responseWidth of responseWidths)
            totalWidth += responseWidth;

        totalWidth += responseSpacing * (responseWidths.length - 1);

        return totalWidth;
    }
}

export default ResponseArray;