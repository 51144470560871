import Config from '../config';
import Icons from './icons';

class ActionContainer {

    static drawIconContainer(canvas, containerColor, backgroundColor, position, size) {

        canvas.fillStyle = containerColor;

        canvas.beginPath();
        canvas.arc(position.x, position.y, size / 2, 0, 2 * Math.PI);
        canvas.fill();

        canvas.fillStyle = backgroundColor;

        canvas.beginPath();
        canvas.arc(position.x, position.y, 0.8 * size / 2, 0, 2 * Math.PI);
        canvas.fill();
    }

    static drawBoxContainer(canvas, color, position, width, height, cornerRadius = Config.Style.ActionBox.CornerRadius) {

        canvas.fillStyle = color;

        canvas.beginPath();

        canvas.arc(position.x - width / 2 + cornerRadius, position.y + cornerRadius, cornerRadius, Math.PI, 1.5 * Math.PI);
        canvas.arc(position.x + width / 2 - cornerRadius, position.y + cornerRadius, cornerRadius, - 0.5 * Math.PI, 0);
        
        canvas.arc(position.x + width / 2 - cornerRadius, position.y + height - cornerRadius, cornerRadius, 0, 0.5 * Math.PI);
        canvas.arc(position.x - width / 2 + cornerRadius, position.y + height - cornerRadius, cornerRadius, 0.5 * Math.PI, Math.PI);
        
        canvas.closePath();
        canvas.fill();
    }

    static drawBoxTitle(canvas, position, containerWidth, text) {
        
        canvas.font = Config.Style.ActionBox.Title.Font;
        canvas.fillStyle = Config.Style.ActionBox.Title.Color;

        let margins = Config.Style.ActionBox.Title.Margin;
        let fittingText = ActionContainer.getFittingText(canvas, containerWidth, text);
        let textHeight = parseInt(canvas.font.match(/\d+/), 10);

        canvas.beginPath();
        canvas.fillText(fittingText, position.x - containerWidth / 2 + margins.Left, position.y + textHeight / 1.5 + margins.Top);
        canvas.fill();
    }

    static getFittingText(canvas, containerWidth, text) {

        canvas.font = Config.Style.ActionBox.Title.Font;

        let marginLeft = Config.Style.ActionBox.Title.Margin.Left;
        let textWidth = canvas.measureText(text).width;

        if(textWidth > containerWidth - 2 * marginLeft) {

            let splitText = text.split(' ');

            let lastFittingText = '';
            let currentText = '';

            for(let word of splitText) {

                currentText += word;
                textWidth = canvas.measureText(currentText + '...').width;

                if(textWidth > containerWidth - 2 * marginLeft)
                    return lastFittingText + '...';

                lastFittingText = currentText;
                currentText += ' ';
            }

            return lastFittingText;
        }
        else {

            return text;
        }
    }

    static drawPlayerPlaceholder(canvas, position, width, height) {

        let playIconColor = '#eee';
        let playerBackgroundColor = '#333';
        let progressColor = '#fc3635'

        let progressValue = 0.3;
        let progressHeight = height / 40;

        let playIconPosition = position.clone();
        playIconPosition.y += height / 2 - progressHeight;

        ActionContainer.drawBoxContainer(canvas, playerBackgroundColor, position, width, height, 7)

        Icons.drawPlayIcon(canvas, playIconColor, playIconPosition, height / 3);

        canvas.fillStyle = playIconColor;

        canvas.beginPath();
        canvas.fillRect(position.x - width / 2, position.y + height - 3 * progressHeight, width, progressHeight);
        canvas.fill();

        canvas.fillStyle = progressColor;

        canvas.beginPath();
        canvas.fillRect(position.x - width / 2, position.y + height - 3 * progressHeight, width * progressValue, progressHeight);
        canvas.fill();

        canvas.beginPath();
        canvas.arc(position.x - width / 2 + width * progressValue, position.y + height - 2.5 * progressHeight, 1.2 * progressHeight, 0, 2 * Math.PI);
        canvas.fill();
    }
}

export default ActionContainer;