export default class Strings {

    static EN = Object.freeze({
        LOADING_RESOURCES: 'Loading resources...',
        LOADING_CONVERSATION: 'Loading conversation...',
        FAILED_LOADING_CONVERSATION: 'Failed to load conversation',
        FAILED_LOADING_RESOURCES: 'Failed to load resources',
        INVALID_INPUT: 'The given input is not valid',
        SUBMIT_BUTTON: 'Submit',
        CANCEL_BUTTON: 'Cancel',
        CONTINUE_BUTTON: 'Continue'
    });
    
    static AL = Object.freeze({
        LOADING_RESOURCES: 'Duke shkarkuar resurset...',
        LOADING_CONVERSATION: 'Duke shkarkuar bisedën...',
        FAILED_LOADING_CONVERSATION: 'Dështim gjat marrjes së bisedës',
        FAILED_LOADING_RESOURCES: 'Dështim gjat marrjes së resurseve',
        INVALID_INPUT: 'Vlera e dhënë nuk është valide',
        SUBMIT_BUTTON: 'Ruaj',
        CANCEL_BUTTON: 'Anulo',
        CONTINUE_BUTTON: 'Vazhdo'
    })
    
    static SR = Object.freeze({
        LOADING_RESOURCES: 'Loading resources...',
        LOADING_CONVERSATION: 'Loading conversation...',
        FAILED_LOADING_CONVERSATION: 'Failed to load conversation',
        FAILED_LOADING_RESOURCES: 'Failed to load resources',
        INVALID_INPUT: 'Unos nije validan',
        SUBMIT_BUTTON: 'Submit',
        CANCEL_BUTTON: 'Cancel',
        CONTINUE_BUTTON: 'Continue'
    });
    
    static getDefaultLanguage() {
        return typeof window.DefaultLanguage !== 'undefined' ? 
               window.DefaultLanguage : Strings.EN
    }
    static setSubmitUrl(url) {
        window.submitUrl = url;
    }
    static setTopicId(topic_id) {
        window.topicId = topic_id;
    }
    static setConversationId(conversation_id) {
        window.conversationId = conversation_id;
    }
    static setDefaultLanguage(language) { 
        window.DefaultLanguage = language 
    }
}