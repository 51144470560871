import Config from '../../config';

import { Point, Size } from '../../helpers';

class StartGeometry {

    constructor(position) {

        this._position = new Point(position.x, position.y);
        this._size = new Size(0, 0);

        this._startPoint = new Point(0, 0);
        this._outputPositions = [ new Point() ];
    }

    translate(dx, dy) {

        this._position.translate(dx, dy);
        this._startPoint.translate(dx, dy);

        this._outputPositions[0].translate(dx, dy);
    }

    updateGeometry(position) {

        let currentPosition = new Point(position.x, position.y);
        
        this._position.copyFrom(currentPosition);

        currentPosition.y += Config.Style.TerminationSymbol.Radius;
        this._startPoint.copyFrom(currentPosition);

        currentPosition.y += Config.Style.TerminationSymbol.Radius + Config.Style.ChatItem.Spacing.Vertical;
        this._outputPositions[0].copyFrom(currentPosition);

        this._updateSize();
    }

    _updateSize() {
        
        this._size.width = 2 * Config.Style.TerminationSymbol.Radius;
        this._size.height = this._outputPositions[0].y - this._position.y;
    }

    getPosition() {

        return this._position;
    }

    getSize() {

        return this._size;
    }

    getInputPosition() {

        return this._inputPosition;
    }

    getOutputPositions() {

        return this._outputPositions;
    }

    getOutputPosition(index) {

        return this._outputPositions[index];
    }
    
    getOutputCount() {

        return this._outputPositions.length;
    }

    getStartPoint() {

        return this._startPoint;
    }
}

export default StartGeometry;