class TextContainer {

    static ContainerType() {

        return {

            Round: 1,
            Trapezoid: 2
        };
    };

    static getTextContainerWidth(canvas, font, sideRadius, text) {

        let tmpFont = canvas.font;

        canvas.font = font;
        let textWidth = canvas.measureText(text).width;

        canvas.font = tmpFont;

        return textWidth + sideRadius;
    }

    static getTextContainerHeight(font) {

        let textHeight = parseInt(font.match(/\d+/), 10);

        return 2 * textHeight;
    }

    static getTextContainerSize(canvas, font, text) {

        let tmpFont = canvas.font;

        canvas.font = font;

        let textWidth = canvas.measureText(text).width;
        let textHeight = parseInt(canvas.font.match(/\d+/), 10);

        let containerHeight = 2 * textHeight;
        let containerWidth = textWidth;

        canvas.font = tmpFont;

        return {

            width: containerWidth + containerHeight,
            height: containerHeight
        };
    }

    static drawTextContainer(canvas, containerColor, textColor, containerType, font, position, text) {

        canvas.font = font;

        let textWidth = canvas.measureText(text).width;
        let textHeight = parseInt(canvas.font.match(/\d+/), 10);

        let containerHeight = 1.8 * textHeight;
        let containerWidth = textWidth;

        canvas.fillStyle = containerColor;

        canvas.beginPath();

        if(containerType === TextContainer.ContainerType().Trapezoid) {

            canvas.moveTo(position.x - containerWidth / 2 - containerHeight / 2, position.y);
            canvas.lineTo(position.x - containerWidth / 2 - containerHeight / 4, position.y + containerHeight);
            canvas.lineTo(position.x + containerWidth / 2 + containerHeight / 4, position.y + containerHeight);
            canvas.lineTo(position.x + containerWidth / 2 + containerHeight / 2, position.y);
            canvas.closePath();
        }
        else {

            canvas.arc(position.x - containerWidth / 2, position.y + containerHeight / 2, containerHeight / 2, Math.PI / 2, Math.PI / 2 + Math.PI);
            canvas.arc(position.x + containerWidth / 2, position.y + containerHeight / 2, containerHeight / 2, -Math.PI / 2, -Math.PI / 2 + Math.PI);
            canvas.closePath();
        }

        canvas.fill();

        canvas.fillStyle = textColor;

        canvas.beginPath();
        canvas.fillText(text, position.x - containerWidth / 2, position.y + containerHeight / 2 + textHeight / 3);
        canvas.fill();
    } 
}

export default TextContainer;