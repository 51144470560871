import ClassBuilder from '../../../../helpers/class_builder';
import Validators from '../../../../helpers/validators';
import Strings from '../../../../strings';
import React from 'react';
import './Input.css';

class Input extends React.Component {
    
    constructor(props) {

        super(props);

        this.state = {

            inputValue: '',
            buttonsDisabled: true,
            fieldDisabled: false,
            class: ClassBuilder.add('chat-item')
                               .add('rosie-measurement-item')
        };
    }

    onAcceptClick() {

        if(this.props.onResponse)
            this.props.onResponse(this.props.elementId, true, this.state.inputValue.trim());

        this.setState({

            fieldDisabled: true,
            buttonsDisabled: true
        });
    }

    onCancelClick() {

        if(this.props.onResponse)
            this.props.onResponse(this.props.elementId, false, null);

        this.setState({

            fieldDisabled: true,
            buttonsDisabled: true,
            showInvalidValueMessage: false
        });
    }

    onInputValueChanged(event) {

        this.setState({
            inputValue: event.target.value,
            buttonsDisabled: !this.isValidInput(event.target.value),
            showInvalidValueMessage: !this.isValidInput(event.target.value)
        });
    }

    onInputKeyPress(event) {
        if(event.key === 'Enter' && !this.state.buttonsDisabled)
            this.onAcceptClick();
    }

    componentDidMount() {

        this.props.onItemAdded();
        
        this.setState({

            class: this.state.class.add('animate')
        });
    }

    isValidInput(input) {

        const variableName = this.props.variableName;
        const variableValue = input.trim();
        
        switch(variableName) {
            case 'VitiLindjes': 
                return Validators.isBirthYear(variableValue);

            case 'FullName': 
                return Validators.isFullName(variableValue);

            case 'Telefoni': 
                return Validators.isPhoneNumber(variableValue);

            default:
                return Validators.isDefaultInput(variableValue);
        }
    }

    componentDidUpdate() {
        this.props.onItemAdded();
    }

    render() {
        return (
            <div className="input-item-container">

                <div className={this.state.class.serialize()}>
        
                    <input type="text" 
                           className="measurement-value" 
                           style={this.state.showInvalidValueMessage ? {marginBottom: 8} : {}}
                           value={this.state.inputValue} 
                           disabled={this.state.fieldDisabled}
                           onChange={this.onInputValueChanged.bind(this)} 
                           onKeyPress={this.onInputKeyPress.bind(this)} autoFocus/>
        
                    {
                        this.state.showInvalidValueMessage ?
                        <p className="invalid-input-text">
                            { Strings.getDefaultLanguage().INVALID_INPUT }
                        </p>
                        :
                        null
                    }

                    <div className="button-holder">
        
                        <button className={this.state.buttonsDisabled ? 'disabled-button' : ''} onClick={this.onAcceptClick.bind(this)} disabled={this.state.buttonsDisabled}>{ Strings.getDefaultLanguage().SUBMIT_BUTTON }</button>
        
                    </div>
                </div>
                
            </div>
        );
    }
}

export default Input;