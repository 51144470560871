import SingleChoice from './elements/single_choice/single_choice';
import Measurement from './elements/measurement/measurement';
import Conditional from './elements/conditional/conditional';
import Comparator from './elements/comparator/comparator';
import Statement from './elements/statement/statement';
import Output from './elements/output/output';
import Action from './elements/action/action';
import Start from './elements/start/start';
import Image from './elements/image/image';
import Video from './elements/video/video';
import Timer from './elements/timer/timer';
import Alert from './elements/alert/alert';
import End from './elements/end/end';

import Stack from '@datastructures-js/stack';
import { Helpers, Point } from './helpers';
import Viewer from './elements/viewer/viewer';
import Input from './elements/input/input';
import Web from './elements/web/web';

class FlowDocument {

    constructor() {

        this._undoStack = Stack();
        this._redoStack = Stack();

        this._clipboard = null;

        this._previousState = [];
        this._elementList = [];

        this._systemVariableList = [];
        this._customVariableList = [];
    }

    getNextId() {

        let maxId = 0;

        for(let element of this.getElements())
            if(element.getModel().getId() > maxId)
                maxId = element.getModel().getId();

        return maxId + 1;
    }

    addElement(element) {

        let elementId = element.getModel().getId();
        let conflictingElement = this.getElement(elementId);

        if(conflictingElement !== null)
            throw new Error(`Element with ID ${elementId} already exists`);

        this._elementList.push(element);
    }

    removeElement(id) {

        let elementToDelete = this.getElement(id);

        if(elementToDelete === null)
            throw new Error(`Element with ID ${id} not found`);

        for(let element of this.getElements())
            for(let i=0; i<element.getModel().getOutputCount(); i++)
                if(element.getModel().getOutput(i) === id)
                    element.getModel().setOutput(i, null);

        let elementIndex = this._elementList.indexOf(elementToDelete);

        this._elementList.splice(elementIndex, 1);
    }

    getElement(id) {

        let foundElement = this._elementList.find(element => element.getModel().isId(id));

        if(Helpers.isDefined(foundElement))
            return foundElement;
        else
            return null;
    }

    getElements() {

        return this._elementList;
    }

    getStartElement() {

        for(let element of this.getElements()) {

            let elementExport = element.getModel().export();
        
            if(elementExport.type === 'start')
                return element;
        }

        return null;
    }

    getVariables() {

        return this.getMeasurementVariables()
                   .concat(this.getSystemVariables(), 
                           this.getCustomVariables());
    }

    getMeasurementVariables() {

        let variableList = [];

        for(let element of this.getElements()) {

            let elementExport = element.getModel().export();

            if(elementExport.type === 'action' && elementExport.intent === 'measurement') {

                let variableName = element.getModel().getParameter();

                variableList.push({

                    name: variableName,
                    type: 'measurement',
                    initialized: 0
                });
            }
        }

        return variableList;
    }

    getSystemVariables() {

        return this._systemVariableList;
    }

    setSystemVariables(variableNames) {

        this._systemVariableList = [];

        for(let variableName of variableNames) {

            this._systemVariableList.push({

                name: variableName,
                type: 'system',
                initialized: 0
            });
        }
    }

    getCustomVariables() {

        return this._customVariableList;
    }

    setCustomVariables(variableNames) {

        this._customVariableList = [];

        for(let variableName of variableNames) {

            this._customVariableList.push({

                name: variableName,
                type: 'custom',
                initialized: 0
            });
        }
    }

    getFirstInvalidVariableElement() {

        let variableList = this.getVariables();

        for(let element of this.getElements()) {

            let elementExport = element.getModel().export();

            if(elementExport.type === 'comparator' || elementExport.type === 'statement') {

                let variable = element.getModel().getVariable();

                if(variable === null)
                    continue;

                if(!Helpers.isDefined(variableList.find(listVariable => listVariable.name === variable)))
                    return element;
            }
        }

        return null;
    }

    initializeNew() {

        let startingPoint = new Start(0, new Point(0, 0));
        this._elementList.push(startingPoint);

        this.snapshotState();
    }

    exportFlow() {

        let exportedItems = [];

        for(let element of this.getElements())
            exportedItems.push(element.getModel().export());

        return {

            flow: exportedItems,
            variables: this.getVariables()
        };
    }

    export() {

        let elementList = [];

        for(let element of this.getElements())
            elementList.push(element.exportElement());

        return {

            elements: elementList,
            variables: this.getVariables()
        };
    }

    import(documentData) {

        let elementList = documentData.elements;
        let variableList = documentData.variables;

        this.parseAndImportElements(elementList);
        this.parseAndImportVariables(variableList);

        this.snapshotState();
    }

    parseAndImportElements(elementDescriptorList) {

        for(let elementDescriptor of elementDescriptorList) {

            let parsedElement = this.parseElement(elementDescriptor);

            if(parsedElement !== null)
                this.addElement(parsedElement);
        }
    }

    parseAndImportVariables(variableList) {

        for(let variable of variableList) {

            switch(variable.type) {

                case 'system':
                    this._systemVariableList.push(variable);
                    break;
                    
                case 'custom':
                    this._customVariableList.push(variable);
                    break;

                default:
            }
        }
    }

    parseElement(elementDescriptor) {

        let createdElement = null;
        elementDescriptor.position = Point.from(elementDescriptor.position);

        switch(elementDescriptor.type) {
            
            case 'start':
                createdElement = Start.create(elementDescriptor);
                break;

            case 'output':
                createdElement = Output.create(elementDescriptor);
                break;
            
            case 'comparator':
                createdElement = Comparator.create(elementDescriptor);
                break;
            
            case 'single-choice':
                createdElement = SingleChoice.create(elementDescriptor);
                break;
            
            case 'timer':
                createdElement = Timer.create(elementDescriptor);
                break;

            case 'measurement':
                createdElement = Measurement.create(elementDescriptor);
                break;

            case 'image':
                createdElement = Image.create(elementDescriptor);
                break;

            case 'video':
                createdElement = Video.create(elementDescriptor);
                break;

            case 'input':
                createdElement = Input.create(elementDescriptor);
                break;

            case 'action':
                createdElement = Action.create(elementDescriptor);
                break;

            case 'viewer':
                createdElement = Viewer.create(elementDescriptor);
                break;

            case 'web':
                createdElement = Web.create(elementDescriptor);
                break;

            case 'alert':
                createdElement = Alert.create(elementDescriptor);
                break;                
                
            case 'statement':
                createdElement = Statement.create(elementDescriptor);
                break;

            case 'conditional':
                createdElement = Conditional.create(elementDescriptor);
                break;

            case 'end':
                createdElement = End.create(elementDescriptor);
                break;

            default:
                createdElement = null;
        }
        
        return createdElement;
    }

    snapshotState() {

        this._previousState = JSON.stringify(this.export());
    }

    documentChanged() {

        this._undoStack.push(this._previousState);
        this._redoStack.clear();

        this.snapshotState();
    }

    undo() {

        if(this.canUndo()) {

            this._redoStack.push(this._previousState);

            this._elementList = [];
            this.parseAndImportElements(JSON.parse(this._undoStack.pop()).elements);

            this.snapshotState();
        }
    }

    redo() {

        if(this.canRedo()) {

            this._undoStack.push(this._previousState);

            this._elementList = [];
            this.parseAndImportElements(JSON.parse(this._redoStack.pop()).elements);

            this.snapshotState();
        }
    }

    canUndo() {

        return !this._undoStack.isEmpty();
    }

    canRedo() {

        return !this._redoStack.isEmpty();
    }

    copy(selectedElements) {

        let exportedItems = [];

        for(let element of selectedElements)
            exportedItems.push(element.exportElement());

        this._clipboard = JSON.stringify(exportedItems);
    }

    paste() {

        if(!this.canPaste())
            return;

        let nextElementId = this.getNextId();
        let clipboardElements = JSON.parse(this._clipboard);
        
        let clipboardElementIds = [];

        for(let element of clipboardElements) {

            let modifiedId = parseInt(element.id) + nextElementId;
            
            element.id = modifiedId;
            clipboardElementIds.push(modifiedId);

            element.position.x += 100 + parseInt(Math.random() * 100);
            element.position.y += 100 + parseInt(Math.random() * 100);
        }

        for(let element of clipboardElements) {

            for(let i=0; i<element.outputs.length; i++) {

                let modifiedOutput = parseInt(element.outputs[i]) + nextElementId;

                if(clipboardElementIds.indexOf(modifiedOutput) !== -1)
                    element.outputs[i] = modifiedOutput;
                else
                    element.outputs[i] = null;
            }
        }

        this.parseAndImportElements(clipboardElements);
    }

    canPaste() {

        return this._clipboard !== null;
    }
}

export default FlowDocument;