class API {

    static encodeBodyParameters(parameters) {

        let encodedParameterString = '';
        let parameterKeys = Object.keys(parameters);

        for(let i=0; i<parameterKeys.length; i++) {

            let parameter = parameterKeys[i];
            let value = encodeURIComponent(parameters[parameter]);

            encodedParameterString += `${parameter}=${value}`;

            if(i < parameterKeys.length - 1)
                encodedParameterString += '&';
        }

        return encodedParameterString;
    }

    static async send(method, endpoint, token, parameters) {

        let callOptions = {

            method: method,
            headers: {
                'token': token,
                'Content-type': 'application/x-www-form-urlencoded; charset=UTF-8'
            },
            body: API.encodeBodyParameters(parameters)
        }

        let loginResponse = await fetch(endpoint, callOptions);

        if(loginResponse.status === 200)
            return loginResponse.json();
        else
            return Promise.reject(loginResponse.statusText)
    }

    static async get(endpoint, token) {

        let callOptions = {

            method: 'get',
            headers: {
                'token': token
            }
        }

        let loginResponse = await fetch(endpoint, callOptions);

        if(loginResponse.status === 200)
            return loginResponse.json();
        else
            return Promise.reject(loginResponse.statusText)
    }

    static async post(endpoint, token, parameters) {

        return API.send('POST', endpoint, token, parameters);
    }

    static async patch(endpoint, token, parameters) {

        return API.send('PATCH', endpoint, token, parameters);
    }
    
    static async delete(endpoint, token) {

        return API.send('DELETE', endpoint, token, {});
    }
}

export default API;