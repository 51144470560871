import Config from '../../config';
import StartGeometry from './geometry';
import StartModel from './model';

import { Helpers } from '../../helpers';
import { Miscellaneous } from '../../building_blocks/blocks';

class Start {

    constructor(id, position, outputs) {

        if(!Helpers.isPoint(position))
            throw new Error(`'position' argument must be of type Point`);

        this._model = new StartModel(id, outputs);
        this._geometry = new StartGeometry(position);

        this._shouldUpdateGeometry = true;
    }

    draw(canvas) {

        if(this._shouldUpdateGeometry) {

            this.getGeometry().updateGeometry(this.getGeometry().getPosition());

            this._shouldUpdateGeometry = false;                    
        }


        Miscellaneous.drawLine(canvas, 
                               Config.Style.Links.Color, 
                               this.getGeometry().getStartPoint(),
                               this.getGeometry().getOutputPosition(0));

        Miscellaneous.drawStartSymbol(canvas, 
                                      this.getGeometry().getStartPoint());

        Miscellaneous.drawInputAnchor(canvas, 
                                      Config.Style.Anchors.Color, 
                                      this.getGeometry().getOutputPosition(0));
    }
    
    invalidate() {

        this._shouldUpdateGeometry = true;
    }

    translate(dx, dy) {

        this.getGeometry().translate(dx, dy);
    }

    isAtLocation(x, y) {

        let position = this.getGeometry().getPosition();
        let size = this.getGeometry().getSize();

        return position.x - size.width / 2 < x && x < position.x + size.width / 2 &&
               position.y < y && y < position.y + size.height;
    }

    getInputPositionAtLocation() {

        return null;
    }

    getOutputPositionAtLocation(location, radius) {

        let outputPositions = this.getGeometry().getOutputPositions();

        for(let outputPosition of outputPositions)
            if(outputPosition.distanceTo(location) < radius)
                return outputPosition;

        return null;
    }

    getOutputAtLocation(location, radius) {

        for(let i=0; i<this.getModel().getOutputCount(); i++)
            if(this.getGeometry().getOutputPosition(i).distanceTo(location) < radius)
                return i;

        return null;
    }

    getModel() {

        return this._model;
    }

    getGeometry() {

        return this._geometry;
    }

    exportElement() {

        return {

            id: this.getModel().getId(),
            type: 'start',
            position: this.getGeometry().getPosition(),
            outputs: this.getModel().getOutputs()
        };
    }

    static create(elementDescriptor) {

        return new Start(elementDescriptor.id, 
                         elementDescriptor.position,
                         elementDescriptor.outputs);
    }
}

export default Start;