export default {

    Editor: {

        Zoom: {

            Min: 0.9,
            Max: 2.4,
            Default: 1.8
        }
    },
    Style: {

        Text: {

            Font: "15pt Calibri, 'Trebuchet MS', sans-serif"
        },
        ChatItem: {

            Spacing: {

                Vertical: 15,
                Horizontal: 20,
                TextLine: 15,
                BetweenQuestionResponse: 30
            },
            Colors: {

                Rosie: {

                    Background: '#EDEEEF',
                    Foreground: '#2C363F'
                },
                Response: {
    
                    Background: '#1DA0F7',
                    Foreground: '#fff'
                },
                Computation: {
    
                    Background: '#F2B5BD',
                    Foreground: '#fff'
                }
            }
        },
        Links: {

            StrokeWidth: 1,
            Color: '#999'
        },
        Anchors: {

            Size: 10,
            StrokeWidth: 1,
            Color: '#999',
            FillColor: '#fff'
        },
        LinkBox: {

            Size: 18,
            StrokeWidth: 4,
            Color: '#E85A5A'
        },
        SelectionBox: {
            
            Padding: 6,
            StrokeWidth: 0.5,
            Color: 'rgba(200, 0, 0, 0.2)'
        },
        GlobalSelectionBox: {
            
            Padding: 10,
            StrokeWidth: 0.5,
            Color: '#E85A5A'
        },
        TerminationSymbol: {

            Radius: 20,
            Color: '#444'
        },
        ActionContainer: {

            Size: 75,
            Title: {

                Colors: {
                
                    Background: '#EDEEEF',
                    Foreground: '#2C363F'
                },
                Font: "14pt Calibri, 'Trebuchet MS', sans-serif"
            }
        },
        Alert: {

            Colors: {

                Background: '#E86A6A',
                Foreground: '#fff'
            }
        },
        Timer: {

            Colors: {

                Background: '#69526D',
                Foreground: '#fff'
            }
        },
        Image: {

            Colors: {

                Background: '#3F352C',
                Foreground: '#E8E0CE'
            }
        },
        Player: {

            Colors: {

                Background: '#2C363F',
                Foreground: '#fff'
            }
        },
        Input: {

            Colors: {

                Background: '#6E8894',
                Foreground: '#FFFFFF'
            }
        },
        Action: {

            Colors: {

                Background: '#445663',
                Foreground: '#FFD400'
            }
        },
        Viewer: {

            Colors: {

                Background: '#FF8A5B',
                Foreground: '#FFFFFF'
            }
        },
        Web: {

            Colors: {

                Background: '#1A908B',
                Foreground: '#F1F7ED'
            }
        },
        Measurement: {

            Colors: {

                Background: '#63724B',
                Foreground: '#fff'
            }
        },
        ElementIcons: {

            Scale: 1.7,
            Title: {

                Font: "15pt Calibri, 'Trebuchet MS', sans-serif",
                Color: '#333',
                Margin: 5
            }
        }
    }
};