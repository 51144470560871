import React from 'react';
import SimulationEngine from '../../../simulator/simulator_engine';
import ErrorMessagePopup from '../../popups/manager/error_message/ErrorMessagePopup';

import Output from '../simulator_items/output/Output';
import SingleChoice from '../simulator_items/single_choice/SingleChoice';
import Measurement from '../simulator_items/measurement/Measurement';
import Input from '../simulator_items/input/Input';
import Video from '../simulator_items/video/Video';
import Image from '../simulator_items/image/Image';
import Timer from '../simulator_items/timer/Timer';

import './Simulator.css';
import Web from '../simulator_items/web/Web';

class Simulator extends React.Component {
    
    constructor(props) {

        super(props);

        this.state = {

            visible: props.hidden ? false : true,
            chatItems: [],
            chatResponses: ''
        }

        this.preloadedImageList = [];

        this.simulatorEngine = new SimulationEngine();
        this.simulatorEngine.onLoadElement = this.addChatElement.bind(this);
        this.simulatorEngine.onLoadResponses = this.setResponses.bind(this);
        
        this.containerRef = React.createRef();

        window.EventBus.on('SIMULATOR_TOGGLE_VISIBILITY', this.toggleVisibility.bind(this));
        window.EventBus.on('SIMULATOR_SET_STATE', this.setVisible.bind(this));
        window.EventBus.on('SIMULATOR_LOAD_CONVERSATION', this.startConversation.bind(this));

        window.simRef = this;

        this.chatContainerRef = React.createRef();
    }

    getWidth() {

        return this.containerRef.current.offsetWidth;
    }

    setVisible(visible) {

        this.setState({

            visible: visible
        })
    }

    toggleVisibility() {

        this.setVisible(!this.state.visible);
    }

    componentDidUpdate() {

        window.EventBus.emit('EDITOR_UPDATE_SIZE', null);
        window.EventBus.emit('SIMULATOR_STATE_CHANGED', null, this.state.visible);
    }

    startConversation(conversationDocument) {

        this.clearChatElements();
        this.clearChatResponses();

        let invalidVariableElement = conversationDocument.getFirstInvalidVariableElement();

        if(invalidVariableElement === null) {

            let conversationFlow = conversationDocument.exportFlow();
            this.preloadImages(conversationFlow);

            this.simulatorEngine.startConversation(conversationFlow);
        }
        else {

            let elementType = invalidVariableElement.getModel().export().type;
            let elementVariable = invalidVariableElement.getModel().getVariable();

            let errorPopup = <ErrorMessagePopup title="Failed to start simulation" text={ `The simulation failed to start because a ${elementType} element contains an invalid variable named "${elementVariable}". The element with the invalid variable has been selected.` }/>
            window.EventBus.emit('POPUP_LOAD_DIALOG_COMPONENT', null, errorPopup);

            window.EventBus.emit('EDITOR_SELECT_ELEMENT', null, invalidVariableElement);
            return;
        }
    }

    preloadImages(conversationFlow) {
        
        let imageElements = conversationFlow.flow.filter(element => element.intent === 'image');

        for(let image of imageElements) {
            
            const imageResource = this.getImageResource(image.data.resource_id);
            
            let imageToLoad = new window.Image();
            imageToLoad.src = imageResource.uri;

            this.preloadedImageList.push(imageToLoad);
        }
    }

    getImageResource(resourceId) {

        return window.Resources
            .find(resourceType => resourceType.type === 'image')
            .instances
            .find(imageResource => imageResource._id === resourceId);
    }

    addChatElement(elementType, elementId, data, responseCallback) {

        let elementToAdd;

        switch(elementType) {

            case 'OUTPUT':
                elementToAdd = <Output key={elementId} elementId={elementId} from="rosie" textLines={data} onResponse={responseCallback} onItemAdded={ this.itemAdded.bind(this) }/>;
                break;

            case 'MEASUREMENT':
                elementToAdd = <Measurement key={elementId} elementId={elementId} description={data} onResponse={responseCallback} onItemAdded={ this.itemAdded.bind(this) }/>;
                break;

            case 'VIDEO':
                elementToAdd = <Video key={elementId} elementId={elementId} resourceId={data.resource_id} onResponse={responseCallback} onItemAdded={ this.itemAdded.bind(this) }/>;
                break;

            case 'IMAGE':
                elementToAdd = <Image key={elementId} elementId={elementId} resourceId={data.resource_id} onResponse={responseCallback} onItemAdded={ this.itemAdded.bind(this) }/>;
                break;

            case 'TIMER':
                elementToAdd = <Timer key={elementId} elementId={elementId} description={data} onResponse={responseCallback} onItemAdded={ this.itemAdded.bind(this) }/>;
                break;

            case 'ALERT':
                elementToAdd = <Output key={elementId} elementId={elementId} from="alert" textLines={data} onResponse={responseCallback} onItemAdded={ this.itemAdded.bind(this) }/>;
                break;

            case 'WEB':
                elementToAdd = <Web key={elementId} elementId={elementId} from="alert" text={data} onResponse={responseCallback} onItemAdded={ this.itemAdded.bind(this) }/>;
                break;

            case 'INPUT':
                elementToAdd = <Input key={elementId} elementId={elementId} variableName={data} onResponse={responseCallback} onItemAdded={ this.itemAdded.bind(this) }/>;
                break;

            case 'UNSUPPORTED':
                elementToAdd = <Output key={elementId} elementId={elementId} from="alert" textLines={data} onResponse={responseCallback} onItemAdded={ this.itemAdded.bind(this) }/>;
                break;

            default:
                elementToAdd = null;
        }

        if(elementToAdd === null)
            return;

        this.setState({
            chatItems: [...this.state.chatItems, elementToAdd]
        });      
    }

    setResponses(elementId, responseList, responseCallback) {

        let elementToAdd = 
            <SingleChoice key={elementId} elementId={elementId} choiceList={responseList} 
                onResponse={(elementId, success, response) => {

                    this.clearChatResponses();

                    let elementToAdd = <Output key={elementId} elementId={elementId} from="user" textLines={[responseList[response]]} onResponse={() => { responseCallback(elementId, success, response); }} onItemAdded={ this.itemAdded.bind(this) } />;
                    
                    this.setState({
                        chatItems: [...this.state.chatItems, elementToAdd]
                    });     
                }}
            />;
    
        this.setState({
            chatResponses: elementToAdd
        });
    }

    clearChatElements() {

        this.setState({
            chatItems: []
        });  
    }

    clearChatResponses() {

        this.setState({
            chatResponses: []
        });  
    }

    itemAdded() {

        this.chatContainerRef.current.scrollTop = this.chatContainerRef.current.scrollHeight;
    }

    render() {

        let containerStyle = this.props.isSimulationApp ? 'app-simulator-container' : 'simulator-container';

        return (
        
            <div className={containerStyle + (this.state.visible ? '' : ' hidden')} ref={ this.containerRef }>


                <div className="chat-header-container">
                    <img className="chat-header-icon" alt="" src={require('../../../images/rosie_blue_fill.png')}></img>
                    <p className="chat-header-text">Powered by Rosie</p>
                </div>

                <div className="chat-container" ref={ this.chatContainerRef }>
                    {this.state.chatItems}
                </div>
        
                <div className="response-container safari_only">
                    {this.state.chatResponses}
                </div>
                
            </div>
        );
    }
}

export default Simulator;