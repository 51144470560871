import ClassBuilder from '../../../../helpers/class_builder';
import Strings from '../../../../strings';
import React from 'react';

import './Video.css';

class Video extends React.Component {
    
    constructor(props) {

        super(props);

        this.state = {

            class: ClassBuilder.add('chat-item')
                               .add('rosie-video-item')
        };

        this.videoElementRef = React.createRef();
    }

    onAcceptClick() {

        this.stopVideo();
        this.props.onResponse(this.props.elementId, true);
        
        this.setState({

            buttonsDisabled: true
        });
    }

    onCancelClick() {

        this.stopVideo();
        this.props.onResponse(this.props.elementId, false);
        
        this.setState({

            buttonsDisabled: true
        });
    }

    componentDidMount() {

        this.props.onItemAdded();
        this.setState({

            class: this.state.class.add('animate')
        });
    }

    stopVideo() {

        if(this.videoElementRef.current.toString().toLowerCase().indexOf('iframe') === -1)
            this.videoElementRef.current.pause();
        else
            this.videoElementRef.current.contentWindow.postMessage('{"event":"command","func":"pauseVideo","args":""}', '*');
    }

    getVideoResourceData(resourceId) {

        let videoResourceList = window.Resources.find(resource => resource.type === 'video');
    
        if(typeof videoResourceList === 'undefined')
            return null;

        return videoResourceList.instances.find(resource => resource._id === resourceId);
    }

    getYouTubeVideoId(url) {

        // eslint-disable-next-line
        var regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
        var match = url.match(regExp);
    
        if (match && match[2].length === 11)
            return match[2];
        else
            return null;
    }

    buildVideoElement(videoUrl) {

        let youTubeVideoId = this.getYouTubeVideoId(videoUrl);

        if(youTubeVideoId === null) {

            return <video className="video-placeholder" ref={ this.videoElementRef } controls autoplay>
                        <source src={videoUrl} type="video/mp4"/>
                   </video>
        }
        else {

            return <iframe ref={ this.videoElementRef }
                           title='ytv'
                           className="video-placeholder" 
                           src={'//www.youtube.com/embed/' + youTubeVideoId + '?enablejsapi=1&version=3&playerapiid=ytplayer&autoplay=1'} 
                           frameBorder="0" 
                           allow="autoplay; encrypted-media" 
                           allowFullScreen="1"> </iframe>
        }
    }

    render() {

        let videoElement = this.buildVideoElement(this.getVideoResourceData(this.props.resourceId).uri);

        return (

            <div className={this.state.class.serialize()}>

                { videoElement }

                <div className="button-holder">
    
                    <button className={this.state.buttonsDisabled ? 'disabled-button' : ''} onClick={this.onAcceptClick.bind(this)} disabled={this.state.buttonsDisabled}>{ Strings.getDefaultLanguage().CONTINUE_BUTTON }</button>
    
                </div>
            </div>
        );
    }
}

export default Video;