import React from 'react';
import SingleChoiceItem from '../single_choice_item/SingleChoiceItem';

import './SingleChoice.css';

class SingleChoice extends React.Component {

    componentWillMount() {

        let choiceCollection = [];

        for(let item=0; item<this.props.choiceList.length; item++) {

            let elementId = this.props.elementId;
            let choiceText = this.props.choiceList[item];
            let responseCallback = this.props.onResponse;

            let itemTag = <SingleChoiceItem key={item} elementId={elementId} responseIndex={item} text={choiceText} onResponse={responseCallback}/>;

            choiceCollection.push(itemTag);
        }

        this.setState({choiceCollection})
    }

    render() {

        return this.state.choiceCollection;
    }
}

export default SingleChoice;