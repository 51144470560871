import Config from '../../config';
import ActionContainer from '../../building_blocks/action_container';
import TextContainer from '../../building_blocks/text_container';
import Icons from '../../building_blocks/icons';

import AlertGeometry from './geometry';
import AlertModel from './model';

import { Helpers } from '../../helpers';
import { Miscellaneous } from '../../building_blocks/blocks';

class Alert {

    constructor(id, position, target, mode, message, title, outputs) {
     
        if(!Helpers.isPoint(position))
            throw new Error(`'position' argument must be of type Point`);

        this._model = new AlertModel(id, target, mode, message, title, outputs);
        this._geometry = new AlertGeometry(position, target);

        this._shouldUpdateGeometry = true;
    }

    draw(canvas) {

        if(this._shouldUpdateGeometry) {

            this.getGeometry().updateGeometry(canvas, 
                                              Config.Style.Text.Font, 
                                              this.getGeometry().getPosition(),
                                              this.getModel().getTitle());

            this._shouldUpdateGeometry = false;                    
        }
            
        Miscellaneous.drawLine(canvas, 
                               Config.Style.Links.Color, 
                               this.getGeometry().getInputPosition(),
                               this.getGeometry().getOutputPosition(0));

        Miscellaneous.drawInputAnchor(canvas, 
                                      Config.Style.Anchors.Color, 
                                      this.getGeometry().getInputPosition());

        Miscellaneous.drawOutputAnchor(canvas, 
                                       Config.Style.Anchors.Color, 
                                       this.getGeometry().getOutputPosition(0));

        ActionContainer.drawIconContainer(canvas, 
                                          Config.Style.ActionContainer.Title.Colors.Background, 
                                          Config.Style.Alert.Colors.Background, 
                                          this.getGeometry().getActionIconPosition(), 
                                          Config.Style.ActionContainer.Size);

        Icons.drawAlertIcon(canvas, 
                            Config.Style.Alert.Colors.Foreground, 
                            this.getGeometry().getActionIconPosition(), 
                            Config.Style.ActionContainer.Size / 2.7)                 

        TextContainer.drawTextContainer(canvas, 
                                        Config.Style.ActionContainer.Title.Colors.Background, 
                                        Config.Style.ActionContainer.Title.Colors.Foreground,
                                        TextContainer.ContainerType().Round,
                                        Config.Style.Text.Font, 
                                        this.getGeometry().getActionTitlePosition(), 
                                        this.getModel().getTitle());
    }

    invalidate() {

        this._shouldUpdateGeometry = true;
    }

    translate(dx, dy) {

        this.getGeometry().translate(dx, dy);
    }

    isAtLocation(x, y) {

        let position = this.getGeometry().getPosition();
        let size = this.getGeometry().getSize();

        return position.x - size.width / 2 < x && x < position.x + size.width / 2 &&
               position.y < y && y < position.y + size.height;
    }

    getInputPositionAtLocation(location, radius) {

        let inputPosition = this.getGeometry().getInputPosition();

        if(inputPosition.distanceTo(location) < radius)
            return inputPosition;
        else
            return null;
    }

    getOutputPositionAtLocation(location, radius) {

        let outputPositions = this.getGeometry().getOutputPositions();

        for(let outputPosition of outputPositions)
            if(outputPosition.distanceTo(location) < radius)
                return outputPosition;

        return null;
    }

    getOutputAtLocation(location, radius) {

        for(let i=0; i<this.getModel().getOutputCount(); i++)
            if(this.getGeometry().getOutputPosition(i).distanceTo(location) < radius)
                return i;

        return null;
    }

    getModel() {

        return this._model;
    }
    
    getGeometry() {

        return this._geometry;
    }

    exportElement() {

        return {

            id: this.getModel().getId(),
            type: 'alert',
            position: this.getGeometry().getPosition(),
            target: this.getModel().getTarget(),
            mode: this.getModel().getMode(),
            message: this.getModel().getMessage(),
            title: this.getModel().getTitle(),
            outputs: this.getModel().getOutputs()
        };
    }

    static create(elementDescriptor) {

        return new Alert(elementDescriptor.id, 
                         elementDescriptor.position, 
                         elementDescriptor.target, 
                         elementDescriptor.mode, 
                         elementDescriptor.message,
                         elementDescriptor.title, 
                         elementDescriptor.outputs);
    }
}

export default Alert;