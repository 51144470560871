import { Helpers } from '../../helpers';

class EndModel {

    constructor(id, success) {

        if(!Helpers.isInteger(id))
            throw new Error(`'id' argument must be an Integer`);

        if(Helpers.isUndefined(success))
            success = true;

        this._id = id;
        this._outputs = [];

        this.setSuccess(success);
    }

    export() {

        return {

            id: this._id,
            type: 'end',
            success: this._success
        };
    }

    isId(id) {

        return this._id === id;
    }

    getId() {

        return this._id;
    }

    setSuccess(success) {

        this._success = success;
    }

    getSuccess() {

        return this._success;
    }

    getOutputs() {

        return this._outputs;
    }
    
    getOutput(index) {

        return this._outputs[index];
    }    

    getOutputCount() {

        return this._outputs.length;
    }

    setOutputs(outputs) {}

    setOutput(index, value) {}
}

export default EndModel;