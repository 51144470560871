export class Helpers {

    static isDefined(value) {

        return typeof value !== 'undefined';
    }

    static isUndefined(value) {

        return typeof value === 'undefined';
    }

    static isArray(value) {

        return Helpers.isDefined(value) && value instanceof Array;
    }

    static isString(value) {

        return Helpers.isDefined(value) && typeof value === 'string';
    }

    static isNumber(value) {

        return Helpers.isDefined(value) && typeof value === 'number';
    }

    static isInteger(value) {

        return Helpers.isNumber(value) && (parseFloat(value) === parseInt(value) );
    }

    static isPoint(value) {

        return Helpers.isDefined(value) && value instanceof Point;
    }

    static isIndexInBounds(index, length) {

        return 0 <= index && index < length
    }

    static truncateUrl(url, maxLength) {

        return url.length < maxLength ? url : url.substr(0, maxLength) + '...'; 
    }
}
export class Point {

    constructor(x, y) {

        this.x = x;
        this.y = y;
    }

    distanceTo(location) {

        return Point.euclideanDistance(this, location);
    }

    copyFrom(point) {

        this.x = point.x;
        this.y = point.y;
    }

    translate(dx, dy) {

        this.x += dx;
        this.y += dy;
    }

    clone() {

        return new Point(this.x, this.y);
    }

    static euclideanDistance(from, to) {

        return Math.sqrt((from.x - to.x) * (from.x - to.x) + 
                         (from.y - to.y) * (from.y - to.y));
    }

    static from(point) {

        if(Helpers.isUndefined(point) || Helpers.isUndefined(point.x) || Helpers.isUndefined(point.y))
            throw new Error(`Argument is not a valid point`)

        return new Point(point.x, point.y);
    }
}

export class Size {

    constructor(width, height) {

        this.width = width;
        this.height = height;
    }

    copyFrom(size) {

        this.width = size.width;
        this.height = size.height;
    }

    clone() {

        return new Size(this.width, this.height);
    }
}