class Icons {

    static drawLandscapeIcon(canvas, color, position, size) {
        
        let containerRadius = size / 1.2;
        
        let spreadAngle = Math.PI / 3;
        let leftAngle = Math.PI / 2 + spreadAngle;
        let leftShallowAngle = Math.PI / 2 + spreadAngle / 1.5;
        let rightAngle = Math.PI / 2 - spreadAngle;
        let rightShallowAngle = Math.PI / 2 - spreadAngle / 1.5;

        canvas.fillStyle = color;

        canvas.beginPath();

        canvas.moveTo(position.x + containerRadius * Math.cos(leftAngle), 
                      position.y + containerRadius * Math.sin(leftAngle));

        canvas.lineTo(position.x - size / 6, position.y);

        canvas.lineTo(position.x + containerRadius * Math.cos(rightShallowAngle), 
                      position.y + containerRadius * Math.sin(rightShallowAngle));

        canvas.closePath();
        canvas.fill();


        canvas.beginPath();

        canvas.moveTo(position.x + containerRadius * Math.cos(leftShallowAngle), 
                      position.y + containerRadius * Math.sin(leftShallowAngle));

        canvas.lineTo(position.x + size / 3, position.y + size / 10);

        canvas.lineTo(position.x + containerRadius * Math.cos(rightAngle), 
                      position.y + containerRadius * Math.sin(rightAngle));

        canvas.closePath();
        canvas.fill();

        
        canvas.beginPath();
        canvas.arc(position.x, position.y - 1, containerRadius, rightAngle, leftAngle);
        canvas.fill();

        canvas.beginPath();
        canvas.arc(position.x + size / 4, position.y - size / 3.5, size / 8, 0, 2 * Math.PI);
        canvas.fill();
    } 

    static drawInputIcon(canvas, color, position, size) {
        
        let fieldHeight = size * 0.5;
        let fieldWidth = size * 1;

        let tmpStrokeWidth = canvas.lineWidth;

        canvas.lineWidth = 2;
        canvas.strokeStyle = color;

        canvas.beginPath();
        canvas.rect(position.x - fieldWidth / 2, position.y - fieldHeight / 2, fieldWidth, fieldHeight);
        canvas.stroke();

        canvas.beginPath();
        canvas.rect(position.x - size / 3, position.y - fieldHeight, size / 4, 2 * fieldHeight);
        canvas.fill();

        canvas.beginPath();
        canvas.moveTo(position.x - size / 2.45 + size / 5, position.y - fieldHeight / 1.3);
        canvas.lineTo(position.x - size / 2.45 + size / 5, position.y + fieldHeight / 1.3);
        canvas.stroke();

        canvas.lineWidth = tmpStrokeWidth;
    } 

    static drawWebIcon(canvas, color, position, size) {

        let tmpStrokeWidth = canvas.lineWidth;

        canvas.lineWidth = 1.8;
        canvas.strokeStyle = color;

        canvas.beginPath();
        canvas.ellipse(position.x, position.y, size / 2, size / 2, 0, 0, 2 * Math.PI, false);
        canvas.stroke();

        canvas.beginPath();
        canvas.ellipse(position.x, position.y, size / 4.5, size / 2, 0, 0, 2 * Math.PI, false);
        canvas.stroke();

        canvas.beginPath();
        canvas.moveTo(position.x - size / 2, position.y);
        canvas.lineTo(position.x + size / 2, position.y);

        canvas.moveTo(position.x - size / 2.3, position.y - size / 4);
        canvas.lineTo(position.x + size / 2.3, position.y - size / 4);

        canvas.moveTo(position.x - size / 2.3, position.y + size / 4);
        canvas.lineTo(position.x + size / 2.3, position.y + size / 4);
        canvas.stroke();

        canvas.lineWidth = tmpStrokeWidth;
    } 
    
    static drawActionIcon(canvas, color, position, size) {
        
        let boltSize = size / 2.1;

        canvas.fillStyle = color;

        canvas.beginPath();
        canvas.moveTo(position.x + boltSize / 6, position.y + boltSize / 6);
        canvas.lineTo(position.x + boltSize / 2.5, position.y - boltSize);
        canvas.lineTo(position.x - boltSize / 1.5, position.y + boltSize / 6);
        canvas.fill();

        canvas.beginPath();
        canvas.moveTo(position.x - boltSize / 6, position.y - boltSize / 6);
        canvas.lineTo(position.x - boltSize / 2.5, position.y + boltSize);
        canvas.lineTo(position.x + boltSize / 1.5, position.y - boltSize / 6);
        canvas.fill();
    } 

    static drawViewerIcon(canvas, color, position, size) {
        
        let chartSize = size / 2.3;
        let barSize = size / 6;

        canvas.fillStyle = color;
        canvas.strokeStyle = color;

        let tmpStrokeWidth = canvas.lineWidth;

        canvas.lineWidth = 1.1;
        canvas.beginPath();
        canvas.moveTo(position.x - chartSize, position.y + chartSize);
        canvas.lineTo(position.x + chartSize, position.y + chartSize);
        canvas.stroke();

        canvas.lineWidth = tmpStrokeWidth;

        canvas.beginPath();
        canvas.rect(position.x - 4 * barSize / 2, position.y - 0.5 * chartSize, barSize, 1.5 * chartSize);
        canvas.rect(position.x - barSize / 2, position.y - chartSize, barSize, 2 * chartSize);
        canvas.rect(position.x + 2 * barSize / 2, position.y, barSize, chartSize);
        canvas.fill();
    } 

    static drawPlayIcon(canvas, color, position, size) {
        
        let playSize = size / 2;
        let playCornerRadius = playSize / 7;

        canvas.fillStyle = color;

        canvas.beginPath();

        canvas.arc(position.x - playSize / 2 + playCornerRadius / 2, position.y - playSize / 2, playCornerRadius, - 0.4 * Math.PI, Math.PI, true);
        canvas.arc(position.x - playSize / 2 + playCornerRadius / 2, position.y + playSize / 2, playCornerRadius, Math.PI, 0.4 * Math.PI, true);
        canvas.arc(position.x + playSize / 2 + playCornerRadius, position.y, playCornerRadius, 0.3 * Math.PI, -0.3 * Math.PI, true);

        canvas.fill();
    } 

    static drawTimerIcon(canvas, color, position, size) {
        
        let strokeWidth = size / 10;
        let handLength = size / 2.8;

        canvas.fillStyle = color;
        canvas.strokeStyle = color;
        canvas.lineWidth = strokeWidth;

        canvas.beginPath();
        canvas.arc(position.x, position.y, size / 2, 1.28 * Math.PI, -0.5 * Math.PI, true);
        canvas.lineTo(position.x, position.y - size / 2 + size / 6);
        canvas.stroke();

        canvas.beginPath();
        canvas.moveTo(position.x + handLength * Math.cos(1.25 * Math.PI), position.y + handLength * Math.sin(1.25 * Math.PI));
        canvas.lineTo(position.x, position.y);
        canvas.stroke();

        canvas.beginPath();
        canvas.arc(position.x, position.y, strokeWidth / 2, 0, 2 * Math.PI);
        canvas.fill();
    }

    static drawMeasureIcon(canvas, color, position, size) {
        
        let strokeWidth = size / 11;
        let handLength = size / 2.8;

        let startAngle = 0.85;
        let endAngle = 2.15;

        canvas.fillStyle = color;
        canvas.strokeStyle = color;
        canvas.lineWidth = strokeWidth;

        canvas.beginPath();
        canvas.arc(position.x, position.y, size / 2, startAngle * Math.PI, endAngle * Math.PI);
        canvas.stroke();

        canvas.lineWidth = size / 15;

        for(let i=startAngle; i<=endAngle; i+= (endAngle - startAngle) / 4) {

            canvas.beginPath();
      
            canvas.moveTo(position.x + (size + strokeWidth) / 2 * Math.cos(i * Math.PI), 
                          position.y + (size + strokeWidth) / 2 * Math.sin(i * Math.PI));

            canvas.lineTo(position.x + handLength * Math.cos(i * Math.PI), 
                          position.y + handLength * Math.sin(i * Math.PI));

            canvas.stroke();
        }

        canvas.beginPath();
        canvas.arc(position.x, position.y, strokeWidth / 1.5, 0, 2 * Math.PI);
        canvas.fill();

        canvas.beginPath();
        canvas.moveTo(position.x + handLength * Math.cos(1.35 * Math.PI), position.y + handLength * Math.sin(1.35 * Math.PI));
        canvas.lineTo(position.x, position.y);
        canvas.stroke();

        canvas.lineWidth = strokeWidth / 1.2;

        canvas.beginPath();
        canvas.moveTo(position.x - 0.17 * size, position.y + 0.35 * size);
        canvas.lineTo(position.x + 0.17 * size, position.y + 0.35 * size);
        canvas.stroke();
    } 

    static drawAlertIcon(canvas, color, position, size) {

        canvas.strokeStyle = color;
        canvas.lineWidth = size / 9;

        canvas.beginPath();
        canvas.moveTo(position.x, position.y - size / 1.6);
        canvas.lineTo(position.x - size / 2.5, position.y + size / 2);
        canvas.lineTo(position.x, position.y + size / 4);
        canvas.lineTo(position.x + size / 2.5, position.y + size / 2);
        canvas.closePath();
        canvas.stroke();
    }
}

export default Icons;