import React from 'react';
import ReactDOM from 'react-dom';
import JSEventBus from 'js-event-bus';
import App from './components/app/App';
import './index.css';

import * as serviceWorker from './serviceWorker';

window.EventBus = new JSEventBus();

ReactDOM.render(<App />, document.getElementById('root'));

serviceWorker.unregister();
