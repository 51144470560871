import React from 'react';

import './SingleChoiceItem.css';

class SingleChoiceItem extends React.Component {

    onMenuItemClick() {
        
        this.props.onResponse(this.props.elementId, true, this.props.responseIndex);
    }

    render() {

        return (
            <div className="response-item" onClick={this.onMenuItemClick.bind(this)}>{this.props.text}</div>
        );
    }
}

export default SingleChoiceItem;