import ClassBuilder from '../../../../helpers/class_builder';
import React from 'react';

import './OutputItem.css';

class OutputItem extends React.Component {
    
    constructor(props) {

        super(props);

        this.state = {

            text: props.text,
            class: ClassBuilder.add('chat-item')
                               .add(props.from + '-chat-item')
        }
    }

    componentDidMount() {
        
        this.props.onItemAdded();
        setTimeout(() => {

            this.setState({

                class: this.state.class.add('animate')
            })
        }, 100);
    }

    render() {
        return (

            <div className={this.state.class.serialize()}>{this.props.text}</div>
        );
    }
}

export default OutputItem;