import React from 'react';

import '../../popups.css';
import './ErrorMessagePopup.css';

class ErrorMessagePopup extends React.Component {
    
    onAction() {

        window.EventBus.emit('POPUP_LOAD_DIALOG_COMPONENT', null, null);
    }

    render() {

        return (
            <div className="dialog-popup-background">
                
                <div className="popup-container unselectable">

                    <div className="popup-title-container">
                        <p className="popup-title">{this.props.title}</p>
                    </div>

                    <hr className="popup-content-divider"/>

                    <div className="error-message-popup-label">
                        { this.props.text }
                    </div>

                    <hr className="popup-content-divider"/>

                    <div className="error-message-popup-buttons-container">
                        <button onClick={ this.onAction.bind(this) }>OK</button>
                    </div>

                </div>
                
            </div>
        );
    }
}

export default ErrorMessagePopup;