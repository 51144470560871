import ClassBuilder from '../../../../helpers/class_builder';
import Strings from '../../../../strings';
import React from 'react';

import './Timer.css';

class Timer extends React.Component {
    
    constructor(props) {

        super(props);

        this.state = {

            class: ClassBuilder.add('chat-item')
                               .add('rosie-video-item')
        };
    }

    onAcceptClick() {

        this.props.onResponse(this.props.elementId, true);
        
        this.setState({

            buttonsDisabled: true
        });
    }

    onCancelClick() {

        this.props.onResponse(this.props.elementId, false);
        
        this.setState({

            buttonsDisabled: true
        });
    }

    componentDidMount() {

        this.props.onItemAdded();
        this.setState({

            class: this.state.class.add('animate')
        });
    }

    render() {
        return (

            <div className={this.state.class.serialize()}>
    
                <div className="video-title">{this.props.description}</div>

                <div className="timer-container">
                    <img alt="" className="timer-placeholder" src={require('../../../../images/timer.png')}/>
                </div>

                <div className="button-holder">

                    <button className={this.state.buttonsDisabled ? 'disabled-button' : ''} onClick={this.onAcceptClick.bind(this)} disabled={this.state.buttonsDisabled}>{ Strings.getDefaultLanguage().CONTINUE_BUTTON }</button>
                    
                </div>
            </div>
        );
    }
}

export default Timer;