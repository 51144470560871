import { Helpers } from '../../helpers';

class ActionModel {

    constructor(id, actionId, description, responseList, outputs) {

        if(!Helpers.isInteger(id))
            throw new Error(`'id' argument must be an Integer`);

        this._id = id;

        this.setActionId(actionId);
        this.setDescription(description);
        this.setResponses(responseList);
        this.setOutputs(outputs);
    }

    export() {

        return {

            id: this._id,
            type: 'action',
            intent: 'action',
            data: {
                resource_id: this._actionId,
                description: this._description
            },
            followups: this._exportFollowups()
        };
    }

    _exportFollowups() {

        let followups = [];

        for(let i=0; i< this._responseList.length; i++) {

            followups.push({

                statement: this._responseList[i],
                followup: this._outputs[i]
            });
        }

        return followups;
    }

    isId(id) {

        return this._id === id;
    }

    getId() {

        return this._id;
    }

    getActionId() {

        return this._actionId;
    }

    setActionId(actionId) {

        if(!Helpers.isString(actionId))
            throw new Error(`'actionId' argument must be a String`);

        this._actionId = actionId;
    }

    getDescription() {

        return this._description;
    }

    setDescription(description) {

        if(!Helpers.isString(description))
            throw new Error(`'description' argument must be a String`);

        this._description = description;
    }

    getOutputs() {

        return this._outputs;
    }
    
    getOutput(index) {

        return this._outputs[index];
    }    

    getOutputCount() {

        return this._outputs.length;
    }

    setOutputs(outputs) {

        if(Helpers.isDefined(outputs))
            if(!Helpers.isArray(outputs) || outputs.length !== this._responseList.length)
                throw new Error(`'outputs' argument must be an Array of length 'responseList'`);

        if(Helpers.isDefined(outputs)) {

            this._outputs = outputs;
        }
        else 
        if(Helpers.isUndefined(this._outputs) || this._outputs.length !== this._responseList.length) {

            this._outputs = [];

            for(let i=0; i<this._responseList.length; i++)
                this._outputs.push(null);
        }
    }

    setOutput(index, value) {

        if(!Helpers.isIndexInBounds(index, this._outputs.length))
            throw new Error(`Index out of bounds`);

        if(!(Helpers.isInteger(value) || value === null))
            throw new Error(`'value' argument must be an Integer or null`);

        this._outputs[index] = value;
    }

    getResponses() {

        return this._responseList;
    }
    
    getResponse(index) {

        return this._responseList[index];
    }    

    getResponseCount() {

        return this._responseList.length;
    }

    setResponses(responseList) {

        if(!Helpers.isArray(responseList))
            throw new Error(`'responseList' argument must be an array of Strings`);

        if(responseList.length < 1)
            throw new Error(`Video element must have at least 1 response`);

        for(let response of responseList)
            if(!Helpers.isString(response))
                throw new Error(`'responseList' argument elements must be Strings`);

        this._responseList = responseList;

        if(Helpers.isUndefined(this._outputs) || this._outputs.length !== responseList.length) {

            this._outputs = [];

            for(let i=0; i<this._responseList.length; i++)
                this._outputs.push(null);
        }
    }

    setResponse(index, value) {

        if(!Helpers.isIndexInBounds(index, this._responseList.length))
            throw new Error(`Index out of bounds`);
    
        if(!Helpers.isString(value))
            throw new Error(`'value' argument must be a String`);

        this._responseList[index] = value;
    }

    addResponse(responseText, position) {

        if(Helpers.isUndefined(position)) {

            this._responseList.push(responseText);
            this._outputs.push(null);
        }
        else {

            if(!Helpers.isIndexInBounds(position, this._responseList.length))
                throw new Error(`Index out of bounds`);

            this._responseList.splice(position, 0, responseText);
            this._outputs.splice(position, 0, null);
        }
    }

    deleteResponse(index) {

        if(this._responseList.length <= 1)
            throw new Error(`Video element must have at least 1 response`);

        if(!Helpers.isIndexInBounds(index, this._responseList.length))
            throw new Error(`Index out of bounds`);

        this._responseList.splice(index, 1);
        this._outputs.splice(index, 1);
    }
}

export default ActionModel;