import { Helpers } from "../editor/helpers";
import { v4 as uuidv4 } from 'uuid';
import API from '../api/api';
import Config from '../config';
import moment from 'moment';
/* eslint-disable no-eval */
class SimulationEngine {

    constructor() {
        window.moment = moment;
        this._flowDocument = null;
        this._conversationId = uuidv4();

        this.onLoadElement = () => {};
        this.onLoadResponses = () => {};
    }

    startConversation(flowDocument) {
    
        this._flowDocument = flowDocument;
        this._loadVariables();
    
        let startItem = this.getStartItem();

        this._loadItem(startItem.followup);
    }
    
    getFlow() {

        return this._flowDocument.flow;
    }

    getVariables() {

        for(let variable of this._flowDocument.variables) {

            switch(variable.name) {

                case 'HourOfDay':
                    variable.initialized = new Date().getHours();
                    break;
                
                case 'PatientName':
                    variable.initialized = 'John';
                    break;
                
                default:
            }
        }

        return this._flowDocument.variables;
    }

    injectVariableValues(inputString) {

        let injectedString = inputString;

        for(let variable of this.getVariables()) {

            var replaceRegex = new RegExp(`{${variable.name}}`, 'g');
            injectedString = injectedString.replace(replaceRegex, eval(variable.name)) 
        }

        return injectedString;
    }
    
    getStartItem() {

        let startElement = this.getFlow().find(element => element.type === 'start');

        if(typeof startElement === 'undefined')
            throw new Error('Conversation does not contain an entry point');

        return startElement;
    }

    getItemById(itemId) {
    
        return this.getFlow().find(item => item.id === itemId);
    }
    
    _loadVariables() {

        for(let variable of this.getVariables()) {

            let value = variable.initialized;

            if(typeof value === 'string')
                value = `'${value}'`;

            eval(`window.${variable.name}=${value};`)
        }
    }

    _loadItem(itemId) {
    
        let item = this.getItemById(itemId);
    
        if(Helpers.isUndefined(item))
            return;

        switch(item.type) {
    
            case 'single-choice':
                this._loadSingleChoice(item);
                break;
    
            case 'output':
                this._loadOutput(item);
                break;

            case 'statement':
                this._loadStatement(item);
                break;

            case 'action':
                this._loadAction(item);
                break;
    
            case 'end':
                this._loadTermination(item);
                break;
            
            case 'comparator':
                this._loadComparator(item);
                break;

            case 'alert':
                this._loadAlert(item);
                break;
    
            case 'multichoice':
                this._loadMultichoice(item);
                break;

            default:
                this._loadUnsupported(item);
                break;
        }
    }
    
    _loadOutput(item) {
    
        let itemId = item.id;

        let injectedTextLines = [];

        for(let textLine of item.textLineVariations[0])
            injectedTextLines.push(this.injectVariableValues(textLine))

        this.onLoadElement('OUTPUT', itemId, injectedTextLines, this.processResponse.bind(this));
    }    

    _loadSingleChoice(item) {
    
        let itemId = item.id;
        let responses = item.choices.map(choice => choice.text);

        this.onLoadResponses(itemId, responses, this.processResponse.bind(this));
    }

    _loadStatement(item) {

        try {

            eval(item.statement);
            this._transferVariables();
            this._loadItem(item.followup);
        }
        catch(err) {

            console.error('SIMULATOR ERROR: Failed to eval statement. Error: ' + err);
        }
    }

    _loadAction(item) {
    
        let itemId = item.id;

        switch(item.intent) {

            case "measurement":
                this.onLoadElement('MEASUREMENT', itemId, item.data.description, this.processResponse.bind(this));
                break;

            case "video":
                this.onLoadElement('VIDEO', itemId, item.data, this.processResponse.bind(this));
                break;

            case "image":
                this.onLoadElement('IMAGE', itemId, item.data, this.processResponse.bind(this));
                break;

            case "timer":
                this.onLoadElement('TIMER', itemId, item.data.description, this.processResponse.bind(this));
                break;

            case "web":
                this.onLoadElement('WEB', itemId, item.data.description, this.processResponse.bind(this));
                break;

            case "input":
                this.onLoadElement('INPUT', itemId, item.data.resource_id, this.processResponse.bind(this));
                break;

            default:
                this.onLoadElement('UNSUPPORTED', itemId, [`The "${item.intent}" element is not supported by the simulator...`], this.processResponse.bind(this));
        }
    }
    
    _loadTermination(item) {

        this.onLoadElement('TERMINATION', item.id, this.processResponse.bind(this));
        this._transferVariables();
    }

    _loadComparator(item) {
    
        try {

            let followupCase = item.followups.find(currentCase => eval(currentCase.statement));
    
            if(Helpers.isDefined(followupCase))
                this._loadItem(followupCase.followup);
            else
                console.error('SIMULATOR ERROR: No correct comparator branch');
        }
        catch(err) {

            console.error('SIMULATOR ERROR: Failed to eval statement. Error: ' + err);
        }
    }

    _loadAlert(item) {
    
        let itemId = item.id;
        let target = item.target;

        this.onLoadElement('ALERT', itemId, [`Alerting ${target}`], this.processResponse.bind(this));
    }

    _loadUnsupported(item) {

        let itemId = item.id;

        this.onLoadElement('UNSUPPORTED', itemId, [`Passing through an element not supported by the simulator...`], this.processResponse.bind(this));
    }

    //////////////////////////////// Responses ////////////////////////////////
    
    processResponse(itemId, success, data) {

        let item = this.getItemById(itemId);

        switch(item.type) {

            case "output":
                this._processOutputResponse(item);
                break;

            case "single-choice":
                this._processSingleChoiceResponse(item, data);
                break;

            case "action":
                this._processActionResponse(item, success, data);
                break;

            case "alert":
                this._processOutputResponse(item);
                break;

            default:
        }
    }

    _processOutputResponse(item) {
            
        this._loadItem(item.followup);
    }

    _processSingleChoiceResponse(item, response) {
    
        let responseItem = item.choices[response];

        this._loadItem(responseItem.followup);
    }
    
    _processActionResponse(item, success, data) {
    
        if(item.intent === 'measurement')
            this._processMeasurement(item, data);

        if(item.intent === 'input')
            this._processInput(item, data);
        
        let responseItem = item.followups[success ? 0 : 1];
        
        this._loadItem(responseItem.followup);
    }
    
    _processMeasurement(item, value) {

        eval(`${item.data.parameter} = ${value};`);
    }

    _processInput(item, value) {

        try {
            eval(`${item.data.resource_id} = '${value}';`);
            this._transferVariables();
        } catch(ex) {}
    }

    _transferVariables() {

        
        if(Helpers.isUndefined(window.submitUrl) || window.submitUrl === '')
            return;

        let postData = {
            conversation_id: this._conversationId
        };

        for(let variable of this._flowDocument.variables)
            postData[variable.name] = window[variable.name];

        var dataToSend = API.encodeBodyParameters(postData);

        return fetch(window.submitUrl, {
            method: 'POST',
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
            body: dataToSend
            })
            .then(response => {})
    }
}

export default SimulationEngine;