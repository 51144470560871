import Config from '../../config';
import TextContainer from '../../building_blocks/text_container';
import ResponseArray from '../../building_blocks/response_array';

import { Point, Size } from '../../helpers';

class ConditionalGeometry {

    constructor(position, responseList) {

        this._position = new Point(position.x, position.y);
        this._size = new Size(0, 0);

        this._textLineContainerHeight = 0;
        this._inputPosition = new Point(0, 0);
        
        this._responsePositions = [];
        this._outputPositions = [];

        for(let i=0; i<responseList.length; i++) {

            this._responsePositions.push(new Point(0, 0));
            this._outputPositions.push(new Point(0, 0));
        }
    }

    translate(dx, dy) {

        this._position.translate(dx, dy);
        this._inputPosition.translate(dx, dy);

        for(let i=0; i<this._outputPositions.length; i++) {

            this._responsePositions[i].translate(dx, dy);
            this._outputPositions[i].translate(dx, dy);
        }
    }

    updateGeometry(canvas, font, position, responseList) {

        let currentPosition = new Point(position.x, position.y);

        if(responseList.length !== this._responsePositions.length) {
            
            this._responsePositions = [];
            this._outputPositions = [];

            for(let i=0; i<responseList.length; i++) {

                this._responsePositions.push(new Point(0, 0));
                this._outputPositions.push(new Point(0, 0));
            }

            this._size.width = 0;
        }

        this._position.copyFrom(currentPosition);
        this._inputPosition.copyFrom(currentPosition);

        this._textLineContainerHeight = TextContainer.getTextContainerHeight(font);

        let responsePositions = ResponseArray.getResponseArrayPositions(canvas, font, currentPosition, responseList);

        currentPosition.y += Config.Style.ChatItem.Spacing.BetweenQuestionResponse;
            
        for(let i=0; i<this._outputPositions.length; i++) {

            this._responsePositions[i].y = currentPosition.y
            this._responsePositions[i].x = responsePositions[i];
        }

        currentPosition.y += this._textLineContainerHeight + Config.Style.ChatItem.Spacing.Vertical + Config.Style.Anchors.Size / 2;
            
        for(let i=0; i<this._outputPositions.length; i++) {

            this._outputPositions[i].y = currentPosition.y;
            this._outputPositions[i].x = responsePositions[i]
        }

        this._updateSize(canvas, font, responseList);
    }

    _updateSize(canvas, font, responseList) {

        this._size.height = this._outputPositions[0].y - this._inputPosition.y;
        this._size.width = ResponseArray.getResponseArrayWidth(canvas, font, responseList);
    }

    getPosition() {

        return this._position;
    }

    getSize() {

        return this._size;
    }

    getInputPosition() {

        return this._inputPosition;
    }

    getOutputPositions() {

        return this._outputPositions;
    }

    getOutputPosition(index) {

        return this._outputPositions[index];
    }

    getOutputCount() {

        return this._outputPositions.length;
    }

    getResponsePositions() {

        return this._responsePositions;
    }

    getResponsePosition(index) {

        return this._responsePositions[index];
    }
    
    getResponseCount() {

        return this._responsePositions.length;
    }

    getTextLinePositions() {

        return this._textLinePositions;
    }

    getTextLinePosition(index) {

        return this._textLinePositions[index];
    }
    
    getTextLineCount() {

        return this._textLinePositions.length;
    }
}

export default ConditionalGeometry;